import './App.css'
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navbar, Footer } from './components'
import {
  MainPage,
  AboutUs,
  ContactUs,
  Downloads,
  Cookies,
  Impressum,
  SearchResults,
  Categories,
  SingleDownload,
  Error,
  Product,
} from './pages'
import ScrollToTop from './helper/ScrollToTop'
import smoothscroll from 'smoothscroll-polyfill'
import { GlobalContext } from './helper/GlobalContext'
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner'
import FloatingBtn from './components/FloatingBtn'

function App() {
  const [navClicked, setNavClicked] = useState(false)
  const [animationCanStart, setAnimationCanStart] = useState(true)

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition === 0) {
      setTimeout(() => {
        setAnimationCanStart(true)
      }, 100)
    }
    //eslint-disable-next-line
  }, [scrollPosition, window.location.pathname])

  const clickValue = {
    navClicked,
    setNavClicked,
    animationCanStart,
    setAnimationCanStart,
  }

  smoothscroll.polyfill()

  return (
    <React.Fragment>
      <Router>
        <GlobalContext.Provider value={clickValue}>
          <Navbar />
          <FloatingBtn />
          <ScrollToTop>
            <Routes>
              <Route exact path='/' element={<MainPage />} />
              <Route exact path='/about' element={<AboutUs />} />
              <Route exact path='/contactus' element={<ContactUs />} />
              <Route exact path='/impressum' element={<Impressum />} />
              <Route exact path='/cookies' element={<Cookies />} />
              <Route exact path='/search/:term' element={<SearchResults />} />
              <Route exact path='/downloads' element={<Downloads />} />
              <Route
                exact
                path='/downloads/:term'
                element={<SingleDownload />}
              />
              <Route exact path='/categories/:id' element={<Categories />} />
              <Route
                exact
                path='/categories/:id/:product'
                element={<Product />}
              />
              <Route exact path='*' element={<Error />} />
              <Route exact path='/404' element={<Error />} />
            </Routes>
          </ScrollToTop>

          <CookieBanner
            message='Diese Website verwendet Cookies, damit die Website funktioniert.'
            showAcceptSelectionButton
            acceptAllButtonText='Alles akzeptieren'
            acceptSelectionButtonText='Akzeptieren'
            necessaryOptionText='Erforderlich'
            preferencesOptionText='Einstellungen'
            statisticsOptionText='Statistiken'
            marketingOptionText='Marketing'
            privacyPolicyLinkText='Cookie policy'
            policyLink='/cookies'
          />
          <Footer />
        </GlobalContext.Provider>
      </Router>
    </React.Fragment>
  )
}

export default App
