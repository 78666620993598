import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './i18n.js'
import { Loading } from './components'
import './index.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Suspense fallback={<Loading />}>
    <App />
  </Suspense>
)
