import React from 'react'
import styled from 'styled-components'
import logoWhite from '../assets/logos/logo_white.png'
import faceIcon from '../assets/icons/face-icon.svg'
import instaIcon from '../assets/icons/insta-icon.svg'
import youtubeIcon from '../assets/icons/youtube-icon.svg'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'

const Footer = () => {
  return (
    <Wrapper>
      <div className='content-container'>
        <div className='block block-1'>
          <NavLink to='/'>
            <img src={logoWhite} alt='CooperHunter white logo' />
          </NavLink>

          <p>Copyright &copy;2022 All rights reserved.</p>
          <div className='social-block'>
            <a
              href='https://www.facebook.com/klimamindenkinek.klimamindenkinek.1'
              target='_blank'
              rel='noreferrer'
              className='icon'>
              <img src={faceIcon} alt='face-icon' />
            </a>
            <a href='#' className='icon'>
              <img src={instaIcon} alt='insta-icon' />
            </a>
            <a
              href='https://www.youtube.com/c/CooperandHunterusa/featured'
              target='_blank'
              rel='noreferrer'
              className='icon'>
              <img src={youtubeIcon} alt='youtube-icon' />
            </a>
          </div>
        </div>
        <div className='block block-2'>
          <ul>
            <li>
              <NavLink to='/'>{t('home')}</NavLink>
            </li>
            {/* <li>
              <NavLink to="/categories">{t('catalogue')}</NavLink>
            </li> */}
            <li>
              <NavLink to='/about'>{t('rolunk')}</NavLink>
            </li>
            <li>
              <NavLink to='/downloads'>{t('downloads')}</NavLink>
            </li>
            <li>
              <NavLink to='/contactus'>{t('kapcsolat')}</NavLink>
            </li>
          </ul>
        </div>
        <div className='block block-3'>
          <ul>
            <li>
              <NavLink to='/impressum'>{t('impressum-title')}</NavLink>
            </li>

            <li>
              <NavLink to='/cookies'>{t('gdpr')}</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-block: 4rem;
  color: var(--primary-bg);
  background: var(--secondary-bg);
  /* padding-inline: 16%; */
  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 100px);
    padding-inline: 5%;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
    width: 1168px;
    max-width: 90%;
    height: 50%;
    gap: 10%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-top: 1rem;
      gap: 5%;
      height: 100%;
    }
  }

  p {
    color: var(--primary-bg);
    font-size: 16px;
  }

  .block {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    @media screen and (max-width: 1024px) {
      margin-block: 0.5rem;
    }
  }

  .block-1 {
    width: 40%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .block-2,
  .block-3 {
    display: flex;
    flex-direction: column;

    ul li {
      list-style-type: none;

      a {
        color: var(--primary-bg);
        transition: all 0.2s ease;
        padding-bottom: 0.2rem;

        :hover {
          text-decoration: underline;
        }
      }
    }
    li {
      padding-bottom: 0.5rem;
    }
  }

  .social-block {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;
    align-items: center;

    a {
      font-size: 1.5rem;
      color: white;
      transition: all 0.2s ease-in;
      height: 100%;
      display: flex;

      img:hover {
        filter: invert(75%) grayscale(50%);
      }
    }

    @media screen and (max-width: 1024px) {
      width: 50%;
      margin: 1rem auto;

      .block-2 {
        margin-block: 2rem;
      }

      a {
        font-size: 3rem;
      }
    }
  }
`

export default Footer
