import PDF1 from '../assets/downloads/certificates/CE certificate_ar_al_v.pdf'
import PDF2 from '../assets/downloads/certificates/CE certificate unit.pdf'
import PDF3 from '../assets/downloads/certificates/CE certificate_Consol .pdf'
import PDF4 from '../assets/downloads/certificates/CE certificate_m.pdf'
import PDF5 from '../assets/downloads/certificates/CE certificate_unit.pdf'
import PDF6 from '../assets/downloads/certificates/CE certificate_VRF_C.pdf'
import PDF7 from '../assets/downloads/certificates/CE certificate_VRF_CMULTI.pdf'
import PDF8 from '../assets/downloads/certificates/CE certificate_vrf4.pdf'
// PDF's - MANUAL
import PDF9 from '../assets/downloads/instructions/GYF31366_CH Alpha-Veritas-Arctic.pdf'
// PDF's - CATALOG
import PDF10 from '../assets/downloads/catalouges/CHV5_catalogue_ENG.pdf'
import PDF11 from '../assets/downloads/catalouges/CH HEAT PUMP.pdf'
import PDF12 from '../assets/downloads/catalouges/CH klima katalógus osztrák.pdf'
// PDF's - DATASHEETS
import PDF13 from '../assets/downloads/datasheets/DAYTONA.pdf'
import PDF14 from '../assets/downloads/datasheets/Hővissaznyerő adatlap.pdf'
import PDF15 from '../assets/downloads/datasheets/Légcsatornás adatlap.pdf'
import PDF16 from '../assets/downloads/datasheets/NORDIC MULTI LIGHT.pdf'
import PDF17 from '../assets/downloads/datasheets/online katalógus_consol.pdf'
import PDF18 from '../assets/downloads/datasheets/online katalógus_Rész8.pdf'
import PDF19 from '../assets/downloads/datasheets/online katalógus_Rész16.pdf'
import PDF20 from '../assets/downloads/datasheets/online katalógus_Rész22.pdf'
import PDF21 from '../assets/downloads/datasheets/online katalógus_Rész24.pdf'
import PDF22 from '../assets/downloads/datasheets/online katalógus_Rész50.pdf'

const certContents = [
  {
    title:
      'CH-S09FTXE-NG Wi-Fi, CH-S12FTXE-NG Wi-Fi, CH-S18FTXE-NG Wi-Fi,   CH-S24FTXE-NG Wi-Fi, CH-S09FTXQ-NG Wi-Fi, CH-S12FTXQ-NG Wi-Fi,        CH-S18FTXQ-NG Wi-Fi, CH-S24FTXQ-NG Wi-Fi, CH-S09FTXLA Wi-Fi, CH-S12FTXLA Wi-Fi, CH-S18FTXLA Wi-Fi, CH-S24FTXLA Wi-Fi',
    url: `${PDF1}`,
  },
  {
    title:
      'CHML-ID18NK Indoor unit, CHV-5SC56NK Indoor unit TC01, TC03      TC04, CHO-1N CHV-5S280NMX Outdoor unit, CH-IC18NK4 Indoor                 unit, CH-ID140RKE unit, CH-IU140RME unit, CHV-5SC56NK Indoor            unit, CE52-24/F( C ), CHV-5S280NMX Outdoor unit, CH-IC24NK4                 Indoor unit, CH-IU18NK4 Outdoor unit, CH-IU24NK4 Outdoor unit',
    url: `${PDF2}`,
  },
  {
    title: 'CH-S09FVX-NG Wi-Fi, CH-S12FVX-NG Wi-Fi, CH-S18FVX-NG Wi-Fi',
    url: `${PDF3}`,
  },
  {
    title: 'CH-S36XL9, CH-S12FTX7, CH-S18X7, CH-S18FTX5',
    url: `${PDF4}`,
  },
  {
    title:
      'CH-HP8.0SINK2(I)/(O,) CH-HP8.0SINK3(I)/(O),                 CH-HP10SINK2(I)/(O), CH-HP10SINK3(I)/(O), CH-HP12SINK2                  CH-HP12SINM2, CH-HP12SINM3(I)/(O), CH-HP14SINK2 CH-HP14SINM2,            CH-HP14SINM3(I)/(O), CH-HP16SINK2 CH-HP16SINM2',
    url: `${PDF5}`,
  },
  {
    title:
      'CHML-ID18NK Indoor unit, CHML-ID18NK Indoor unit, CHML-U42NK5      Outdoor unit, CHV-5SC56NK Indoor unit, CHV-5SC56NK Indoor                 unit, TC01, TC03 TC04, CHO-1N CE52-24/F( C ), CHV-5S280NMX                 Outdoor unit,, CHV-5S280NMX Outdoor unit CH-IC18NK4 Indoor                 unit, CH-IC24NK4 Indoor unit, CH-IU18NK4 Outdoor unit,                  CH-IU24NK4 Outdoor unit',
    url: `${PDF6}`,
  },
  {
    title:
      'CHML-ID18NK Indoor unit, CHV-5SC56NK Indoor unit, TC01, TC03     TC04, CHO-1N CHV-5S280NMX Outdoor unit, CH-IC18NK4 Indoor               unit, CHML-U18RK2 Indoor unit, CHML-U42RK5 Outdoor unit,               CHV-5SC56NK Indoor unit, CE52-24/F( C ), CHV-5S280NMX Outdoor           unit, CH-IC24NK4 Indoor unit, CH-IU18NK4 Outdoor unit,                  CHML-U24RK3 Outdoor unit',
    url: `${PDF7}`,
  },
  {
    title:
      'CHV-5S280NMX Outdoor unit, CHV-5S280NMXB Outdoor unit,        CHV-5S280SNMX Outdoor unit, CHV-5S280SNMX2 Outdoor unit,             CHV-5S335NMX Outdoor unit, CHV-5S335NMXB Outdoor unit,                 CHV-5S335SNMX Outdoor unit, CHV-5S335SNMX2 Outdoor unit,                  CHV-5S400NMX Outdoor unit, CHV-5S400NMXB Outdoor unit,                  CHV-5S450NMX Outdoor unit, CHV-5S504NMX Outdoor unit,                  CHV-5S560NMX Outdoor unit, CHV-5S615NMX Outdoor unit,                  CHV-5S615NMXB Outdoor unit, CHV-5S785MX Outdoor unit,                  CHV-5S900MX Outdoor unit, CHV-5SH280NMX2 Outdoor unit,                  CHV-5SH400NMX2 Outdoor unit, CHV-5SH450NMX2 Outdoor unit',
    url: `${PDF8}`,
  },
]

const manualContents = [
  {
    title:
      'Alpha NG Inverter Series C&H, Veritas NG Inverter Series C&H, Arctic NG Inverter Series',
    url: `${PDF9}`,
  },
]

const catalogContents = [
  {
    title: 'CHV5 catalogue EN',
    url: `${PDF10}`,
  },
  {
    title: 'CH Heat Pump EN',
    url: `${PDF11}`,
  },
  {
    title: 'CH Klima calalogue DE',
    url: `${PDF12}`,
  },
]

const dataContents = [
  {
    title: 'Daytona Series',
    url: `${PDF13}`,
  },
  {
    title: 'ENERGY RECOVERY VENTILATION SYSTEM',
    url: `${PDF14}`,
  },
  {
    title: 'NORDIC COMMERCIAL DUCT TYPE SERIES R',
    url: `${PDF15}`,
  },
  {
    title: 'NORDIC MULTI LIGHT SERIES',
    url: `${PDF16}`,
  },
  {
    title: 'INVERTER CONSOLE (WI-FI) SERIES',
    url: `${PDF17}`,
  },
  {
    title: 'SUPREME SERIES',
    url: `${PDF18}`,
  },
  {
    title: 'ARCTIC INVERTER NG SERIES',
    url: `${PDF19}`,
  },
  {
    title: 'VERITAS NG SERIES',
    url: `${PDF20}`,
  },
  {
    title: 'ALPHA NG SERIES',
    url: `${PDF21}`,
  },
  {
    title: 'CASSETTE TYPE SERIES N',
    url: `${PDF22}`,
  },
]

export { certContents, catalogContents, dataContents, manualContents }
