import styled from 'styled-components'
import close from '../assets/icons/close.svg'

const Modal = ({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft,
}) => {
  const handleClick = () => {
    setClickedImg(undefined)
  }

  return (
    <Wrapper>
      <div className='overlay'>
        <img src={clickedImg} alt='bigger pic' />
        <span className='close-icon'>
          <img src={close} alt='close icon' onClick={handleClick} />
        </span>
        <div onClick={handelRotationLeft} className='overlay-arrows_left'>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='48'
              width='48'
              fill='currentColor'>
              <path d='M20 44 0 24 20 4 22.8 6.85 5.65 24 22.8 41.15Z' />
            </svg>
          </div>
        </div>
        <div onClick={handelRotationRight} className='overlay-arrows_right'>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='48'
              width='48'
              fill='currentColor'>
              <path d='M15.2 43.9 12.4 41.05 29.55 23.9 12.4 6.75 15.2 3.9 35.2 23.9Z' />
            </svg>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(18, 18, 18, 0.9);
    display: flex;
    align-items: center;
    z-index: 99999;
  }

  .overlay img {
    display: block;
    max-width: 85%;
    max-height: 85%;
    margin: 60px auto;

    @media screen and (max-width: 769px) {
      width: 100%;
      height: auto;
      margin: auto;
    }
  }
  .overlay > span {
    position: absolute;
    top: 20px;
    right: 35px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
  }
  .overlay-arrows_left {
    display: flex;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    width: 80px;
    height: 80px;
    left: 5%;
    z-index: 999;
    border-radius: 25px;

    @media screen and (max-width: 769px) {
      top: 75%;
      left: 10%;
    }
  }
  .overlay-arrows_left svg,
  .overlay-arrows_right svg {
    width: 50px;
    height: 50px;
    margin-left: 0.8rem;
    margin-top: 0.4rem;
  }
  .overlay-arrows_left:hover,
  .overlay-arrows_right:hover {
    background-color: #0054a3;
    color: #fff;
    cursor: pointer;
  }

  .overlay-arrows_right {
    display: flex;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5%;
    width: 80px;
    height: 80px;
    top: 50%;
    z-index: 999;
    border-radius: 25px;

    @media screen and (max-width: 769px) {
      top: 75%;
      right: 10%;
    }
  }

  .close-icon {
    transition: all 0.3s ease-in;

    :hover {
      transform: rotate(180deg);
    }
  }
`

export default Modal

// import styled from 'styled-components'
// import close from '../assets/icons/close.svg'

// const Modal = ({
//   clickedImg,
//   setClickedImg,
//   handelRotationRight,
//   handelRotationLeft,
// }) => {
//   const handleClick = () => {
//     setClickedImg(undefined)
//   }

//   return (
//     <Wrapper>
//       <div className='overlay'>
//         <img src={clickedImg} alt='bigger pic' />
//         <span className='close-icon'>
//           <img src={close} alt='close icon' onClick={handleClick} />
//         </span>
//         <div className='arrows-container'>
//           <div className='overlay-arrows_left'>
//             <svg
//               onClick={handelRotationLeft}
//               xmlns='http://www.w3.org/2000/svg'
//               height='48'
//               width='48'
//               fill='#ffffff'>
//               <path d='M20 44 0 24 20 4 22.8 6.85 5.65 24 22.8 41.15Z' />
//             </svg>
//           </div>
//           <div className='overlay-arrows_right'>
//             <svg
//               onClick={handelRotationRight}
//               className='overlay-arrows_right'
//               xmlns='http://www.w3.org/2000/svg'
//               height='48'
//               width='48'
//               fill='#ffffff'>
//               <path d='M15.2 43.9 12.4 41.05 29.55 23.9 12.4 6.75 15.2 3.9 35.2 23.9Z' />
//             </svg>
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   )
// }

// const Wrapper = styled.section`
//   .overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     width: 100vw;
//     height: 100vh;
//     background: rgba(18, 18, 18, 0.9);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 99999;
//   }
//   .overlay img {
//     display: block;
//     max-width: 85%;
//     max-height: 85%;
//     margin: 60px auto;
//     background: rgba(18, 18, 18, 0.9);
//   }

//   .overlay > span {
//     position: absolute;
//     display: flex;
//     top: 20px;
//     right: 35px;
//     font-size: 30px;
//     color: #ffffff;
//     z-index: 999;
//     cursor: pointer;
//   }

//   .arrows-container {
//     position: absolute;
//     height: 100px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     left: 0;
//     top: 45%;
//     width: 100%;
//     padding-inline: 5%;
//   }

//   .overlay-arrows_left,
//   .overlay-arrows_right {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100px;
//     height: 100px;
//   }

//   .overlay-arrows_left svg:hover,
//   .overlay-arrows_right svg:hover {
//     cursor: pointer;
//   }

//   .close-icon {
//     transition: all 0.3s ease-in;

//     :hover {
//       transform: rotate(180deg);
//     }
//   }
// `

// export default Modal
