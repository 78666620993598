import React from 'react'
import styled from 'styled-components'
import heroImage from '../assets/images/downloads-hero-image.webp'
import heroImageMobile from '../assets/images/download-hero-image-mobile.webp'
import specImage from '../assets/icons/specifications.svg'
import certImage from '../assets/icons/certifications.svg'
import manualImage from '../assets/icons/user-manuals.svg'
import catalogImage from '../assets/icons/catalogue.svg'

import mountainImage from '../assets/images/mountains.webp'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Downloads = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Cooperklima - Dokumente' />
        <title>Cooperklima | Dokumente</title>
      </Helmet>
      <div className='downloads-hero-container'>
        <h1>{t('downloads')}</h1>
      </div>
      <div className='downloads-container'>
        <div className='row-1'>
          <div>
            <img src={catalogImage} alt='catalogues' />
            <h2>{t('catalog')}</h2>
            <p>{t('cat-text')}</p>
            <NavLink className='download-button' to='/downloads/catalog'>
              <p>{t('more-btn')}</p>
            </NavLink>
          </div>
          <div>
            <img src={certImage} alt='certifications' />
            <h2>{t('certifications')}</h2>
            <p>{t('cert-text')}</p>
            <NavLink className='download-button' to='/downloads/certifications'>
              <p>{t('more-btn')}</p>
            </NavLink>
          </div>
        </div>
        <div className='row-2'>
          <div>
            <img src={manualImage} alt='user manuals' />
            <h2>{t('user-manuals')}</h2>
            <p>{t('manual-text')}</p>
            <NavLink className='download-button' to='/downloads/user-manuals'>
              <p>{t('more-btn')}</p>
            </NavLink>
          </div>
          <div>
            <img src={specImage} alt='user manuals' />
            <h2>{t('datasheets')}</h2>
            <p>{t('datasheet-text')}</p>
            <NavLink className='download-button' to='/downloads/datasheets'>
              <p>{t('more-btn')}</p>
            </NavLink>
          </div>
        </div>
      </div>
      {/* <div className="what-can-we-do-container">
        <div className="what-can-we-do-title-container">
          <div className="what-can-we-do-title-text-container">
            <h2>{t('what-can-we-do')}</h2>
          </div>

          <img src={whiteLogo} alt="white logo" />
        </div>
        <div className="what-can-we-do-text-container">
          <p className="text-1">{t('what-can-we-do-text-1')}</p>
        </div>
      </div> */}
    </Wrapper>
  )
}

const Wrapper = styled.main`
  padding-top: 100px;
  background-color: #e5e5e5;
  .downloads-hero-container {
    background: url(${heroImage});
    height: 28rem;
    width: 100%;

    @media screen and (max-width: 1024px) {
      background: url(${heroImageMobile}) no-repeat center/cover;
    }

    h1 {
      transform: translateX(58vw) translateY(13rem);
      color: var(--primary-bg);
      text-transform: uppercase;
      font-weight: 700;
      font-size: clamp(2rem, 3.2vw, 3.5rem);
    }
  }

  .downloads-container {
    width: 1168px;
    max-width: 90%;
    margin-top: 10rem;
    margin-bottom: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .row-1,
    .row-2 {
      gap: 5rem;
      width: 100%;
    }

    .row-1 > div,
    .row-2 > div {
      width: 100%;
      text-align: center;
    }
    img {
      margin-bottom: 1rem;
    }
    h2 {
      margin-bottom: 2rem;
      font-size: clamp(1.2rem, 1.7vw, 2rem);
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
    }
    p {
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
    }

    .download-button {
      display: block;
      border: 2px solid #000;
      padding: 3%;
      margin-top: 2rem;
      text-align: center;
      p {
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: var(--paragraph-font-size);
      }
    }

    .download-button:hover {
      background-color: var(--title-text-color);
      cursor: pointer;
      p {
        color: var(--primary-bg);
      }
    }
  }
  .downloads-container > div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
  }

  .what-can-we-do-container {
    margin-top: 62px;
    background: url(${mountainImage});
    min-height: fit-content;
    width: 100%;
    padding-top: 12rem;
    padding-bottom: 6rem;
  }

  .what-can-we-do-title-container {
    width: 1168px;
    max-width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 73px;

    h2 {
      color: var(--primary-bg);
      font-size: clamp(2rem, 2.6vw, 3.5rem);
      font-family: 'Montserrat', sans-serif;
    }
  }

  .what-can-we-do-title-text-container {
    width: 1168px;
    max-width: 90%;
    margin: auto;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
    display: flex;
    justify-content: fl;
    align-items: flex-start;
  }
  .what-can-we-do-text-container {
    width: 1168px;
    max-width: 90%;
    margin: auto;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .text-1 {
      width: 1100px;
      max-width: 90%;
      margin-left: auto;
      margin-top: 70px;
      margin-bottom: 58px;
      color: var(--primary-bg);
      font-size: clamp(1rem, 1.4vw, 1.4rem);
      font-family: 'Poppins', 'Arial';
      font-weight: 300;

      @media screen and (max-width: 1024px) {
        margin: auto;
        margin-block: 50px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .downloads-container {
      flex-direction: column;
      margin-top: 5rem;
      margin-bottom: 3rem;
      min-height: 100vh;
    }
    .downloads-container > div {
      width: 100%;
      margin-block: 3rem;
    }

    .downloads-hero-container {
      height: 30rem;
      justify-content: center;
      display: flex;
      h1 {
        margin-top: 4rem;
        transform: none;
      }
    }
    /* .cert-download-container {
      margin-top: 5rem;
      margin-bottom: 5rem;
    } */
    .what-can-we-do-container {
      height: fit-content;
      padding-top: 0;
      padding-bottom: 0;
    }

    .what-can-we-do-title-container {
      flex-direction: column;
      height: fit-content;
      align-items: center;
      margin-top: 5rem;
      padding-left: 5%;
      padding-right: 5%;
      img {
        width: 40%;
      }
    }
    .what-can-we-do-title-text-container {
      order: 2;
      margin-top: 2rem;
    }
  }
`

export default Downloads
