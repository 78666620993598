// PDF PARAPHET
import paraphetPDF from '../assets/downloads/products/Consol adatlap.pdf'

// PDF ALPHA
import alphaPDF from '../assets/downloads/products/alpha.pdf'

// PDF ARCTIC
import arcticPDF from '../assets/downloads/products/arctic.pdf'

// PDF VERITAS
import veritasPDF from '../assets/downloads/products/veritas.pdf'

// PDF SUPREME Kontinental
import supremeKontPDF from '../assets/downloads/products/supreme_kontinental.pdf'

// PDF NORDIC
import nordicPDF from '../assets/downloads/products/nordic_evo2.pdf'

// PDF VITAL
import vitalPDF from '../assets/downloads/products/vital.pdf'

// PDF WARMEPUMPEN SCHWIMMBAD
import poolPDF from '../assets/products/pool/Poolwaermepumpe preisliste 2022 ÁRNÉLKÜL.pdf'

// PDF WARMEPUMPEN NEW
import warmeBasicPDF from '../assets/products/warmepumpe/basic/CH HEAT PUMP.pdf'

// KONSOLEN IMAGES
import konsolenImg1 from '../assets/products/SPLIT/Konsolen/konsolen1.webp'
import konsolenImg2 from '../assets/products/SPLIT/Konsolen/konsolen2.webp'
import konsolenImg3 from '../assets/products/SPLIT/Konsolen/konsolen3.webp'
import konsolenImg4 from '../assets/products/SPLIT/Konsolen/konsolen4.webp'
import konsolenImg5 from '../assets/products/SPLIT/Konsolen/konsolen5.webp'
import konsolenImg6 from '../assets/products/SPLIT/Konsolen/konsolen6.webp'
import konsolenImg7 from '../assets/products/SPLIT/Konsolen/konsolen7.webp'

// ALPHA IMAGES
import alphaImg1 from '../assets/products/SPLIT/Alpha/alpha1.webp'
import alphaImg2 from '../assets/products/SPLIT/Alpha/alpha2.webp'
import alphaImg3 from '../assets/products/SPLIT/Alpha/alpha3.webp'
import alphaImg4 from '../assets/products/SPLIT/Alpha/alpha4.webp'
import alphaImg5 from '../assets/products/SPLIT/Alpha/alpha5.webp'
import alphaImg6 from '../assets/products/SPLIT/Alpha/alpha6.webp'

// ARCTIC IMAGES
import arcticImg1 from '../assets/products/SPLIT/Arctic/arctic1.webp'
import arcticImg2 from '../assets/products/SPLIT/Arctic/arctic2.webp'
import arcticImg3 from '../assets/products/SPLIT/Arctic/arctic3.webp'
import arcticImg4 from '../assets/products/SPLIT/Arctic/arctic4.webp'
import arcticImg5 from '../assets/products/SPLIT/Arctic/arctic5.webp'
import arcticImg6 from '../assets/products/SPLIT/Arctic/arctic6.webp'
import arcticImg7 from '../assets/products/SPLIT/Arctic/arctic7.webp'

// VERITAS IMAGES
import veritasImg1 from '../assets/products/SPLIT/Veritas/veritas1.webp'
import veritasImg2 from '../assets/products/SPLIT/Veritas/veritas2.webp'
import veritasImg3 from '../assets/products/SPLIT/Veritas/veritas3.webp'
import veritasImg4 from '../assets/products/SPLIT/Veritas/veritas4.webp'
import veritasImg5 from '../assets/products/SPLIT/Veritas/veritas5.webp'
import veritasImg6 from '../assets/products/SPLIT/Veritas/veritas6.webp'

// SUPREME KONTINENTAL IMAGES
import supremeImg1 from '../assets/products/SPLIT/Supreme-kontinental/supreme1.webp'
import supremeImg2 from '../assets/products/SPLIT/Supreme-kontinental/supreme2.webp'
import supremeImg3 from '../assets/products/SPLIT/Supreme-kontinental/supreme3.webp'
import supremeImg4 from '../assets/products/SPLIT/Supreme-kontinental/supreme4.webp'
import supremeImg5 from '../assets/products/SPLIT/Supreme-kontinental/supreme5.webp'
import supremeImg6 from '../assets/products/SPLIT/Supreme-kontinental/supreme6.webp'
import supremeImg7 from '../assets/products/SPLIT/Supreme-kontinental/supreme7.webp'
import supremeImg8 from '../assets/products/SPLIT/Supreme-kontinental/supreme8.webp'

// NORDIC EVO 2 IMAGES
import nordicImg1 from '../assets/products/SPLIT/Nordic-evo/nordic1.webp'
import nordicImg2 from '../assets/products/SPLIT/Nordic-evo/nordic2.webp'
import nordicImg3 from '../assets/products/SPLIT/Nordic-evo/nordic3.webp'
import nordicImg4 from '../assets/products/SPLIT/Nordic-evo/nordic4.webp'
import nordicImg5 from '../assets/products/SPLIT/Nordic-evo/nordic5.webp'

// VITAL IMAGES
import vitalImg1 from '../assets/products/SPLIT/Vital/vital1.webp'
import vitalImg2 from '../assets/products/SPLIT/Vital/vital2.webp'
import vitalImg3 from '../assets/products/SPLIT/Vital/vital3.webp'
import vitalImg4 from '../assets/products/SPLIT/Vital/vital4.webp'
import vitalImg5 from '../assets/products/SPLIT/Vital/vital5.webp'
import vitalImg6 from '../assets/products/SPLIT/Vital/vital6.webp'

// ==============================================================

// SPLIT OUTDOOR IMAGES
import outdoor1 from '../assets/products/SPLIT/Outdoor/outdoorUnit1.webp'
import outdoor2 from '../assets/products/SPLIT/Outdoor/outdoorUnit2.webp'
import outdoor3 from '../assets/products/SPLIT/Outdoor/outdoorUnit3.webp'

// ==============================================================

// MULTI ALPHA IMAGES
import multiAlpha1 from '../assets/products/Multi-klimaanlagen/Alpha/multi-alpha-1.webp'
import multiAlpha2 from '../assets/products/Multi-klimaanlagen/Alpha/multi-alpha-2.webp'
import multiAlpha3 from '../assets/products/Multi-klimaanlagen/Alpha/multi-alpha-3.webp'
import multiAlpha4 from '../assets/products/Multi-klimaanlagen/Alpha/multi-alpha-4.webp'
import multiAlpha5 from '../assets/products/Multi-klimaanlagen/Alpha/multi-alpha-5.webp'
import multiAlpha6 from '../assets/products/Multi-klimaanlagen/Alpha/multi-alpha-6.webp'
// MULTI ALPHA PDF
import multiAlphaPdf from '../assets/downloads/products/multi/Alpha.pdf'

// MULTI VERITAS IMAGES
import multiVeritas1 from '../assets/products/Multi-klimaanlagen/Veritas/multi-veritas-1.webp'
import multiVeritas2 from '../assets/products/Multi-klimaanlagen/Veritas/multi-veritas-2.webp'
import multiVeritas3 from '../assets/products/Multi-klimaanlagen/Veritas/multi-veritas-3.webp'
import multiVeritas4 from '../assets/products/Multi-klimaanlagen/Veritas/multi-veritas-4.webp'
// MULTI VERITAS PDF
import multiVeritasPdf from '../assets/downloads/products/multi/Veritas.pdf'

// MULTI VITAL IMAGES
import multiVital1 from '../assets/products/Multi-klimaanlagen/Vital/multi-vital-1.webp'
import multiVital2 from '../assets/products/Multi-klimaanlagen/Vital/multi-vital-2.webp'
import multiVital3 from '../assets/products/Multi-klimaanlagen/Vital/multi-vital-3.webp'
import multiVital4 from '../assets/products/Multi-klimaanlagen/Vital/multi-vital-4.webp'
import multiVital5 from '../assets/products/Multi-klimaanlagen/Vital/multi-vital-5.webp'
import multiVital6 from '../assets/products/Multi-klimaanlagen/Vital/multi-vital-6.webp'
// MULTI VITAL PDF
import multiVitalPdf from '../assets/downloads/products/multi/Vital.pdf'

// MULTI SUPREME IMAGES
import multiSupreme1 from '../assets/products/Multi-klimaanlagen/Supreme/multi-supreme-1.webp'
import multiSupreme2 from '../assets/products/Multi-klimaanlagen/Supreme/multi-supreme-2.webp'
import multiSupreme3 from '../assets/products/Multi-klimaanlagen/Supreme/multi-supreme-3.webp'
import multiSupreme4 from '../assets/products/Multi-klimaanlagen/Supreme/multi-supreme-4.webp'
import multiSupreme5 from '../assets/products/Multi-klimaanlagen/Supreme/multi-supreme-5.webp'
import multiSupreme6 from '../assets/products/Multi-klimaanlagen/Supreme/multi-supreme-6.webp'
// MULTI SUPREME PDF
import multiSupremePdf from '../assets/downloads/products/multi/Supreme.pdf'

// MULTI KONSOLEN IMAGES
import multiKonsolen1 from '../assets/products/Multi-klimaanlagen/Konsolen/multi-konsolen-1.webp'
import multiKonsolen2 from '../assets/products/Multi-klimaanlagen/Konsolen/multi-konsolen-2.webp'
import multiKonsolen3 from '../assets/products/Multi-klimaanlagen/Konsolen/multi-konsolen-3.webp'
import multiKonsolen4 from '../assets/products/Multi-klimaanlagen/Konsolen/multi-konsolen-4.webp'
import multiKonsolen5 from '../assets/products/Multi-klimaanlagen/Konsolen/multi-konsolen-5.webp'
import multiKonsolen6 from '../assets/products/Multi-klimaanlagen/Konsolen/multi-konsolen-6.webp'
import multiKonsolen7 from '../assets/products/Multi-klimaanlagen/Konsolen/multi-konsolen-7.webp'
// MULTI KONSOLEN PDF
import multiKonsolenPdf from '../assets/downloads/products/multi/Konsolen.pdf'

// MULTI LEITUNG IMAGES
import multiLeitung1 from '../assets/products/Multi-klimaanlagen/Leitung/multi-leitung-1.webp'
import multiLeitung2 from '../assets/products/Multi-klimaanlagen/Leitung/multi-leitung-2.webp'
import multiLeitung3 from '../assets/products/Multi-klimaanlagen/Leitung/multi-leitung-3.webp'
import multiLeitung4 from '../assets/products/Multi-klimaanlagen/Leitung/multi-leitung-4.webp'
import multiLeitung5 from '../assets/products/Multi-klimaanlagen/Leitung/multi-leitung-5.webp'
// MULTI KANALKLIMA PDF
import multiKanalklimaPdf from '../assets/downloads/products/multi/Kanalklima.pdf'

// MULTI KASSETTEN IMAGES
import multiKassetten1 from '../assets/products/Multi-klimaanlagen/Kassetten/multi-kassetten-1.webp'
import multiKassetten2 from '../assets/products/Multi-klimaanlagen/Kassetten/multi-kassetten-2.webp'
import multiKassetten3 from '../assets/products/Multi-klimaanlagen/Kassetten/multi-kassetten-3.webp'
import multiKassetten4 from '../assets/products/Multi-klimaanlagen/Kassetten/multi-kassetten-4.webp'
import multiKassetten5 from '../assets/products/Multi-klimaanlagen/Kassetten/multi-kassetten-5.webp'
import multiKassetten6 from '../assets/products/Multi-klimaanlagen/Kassetten/multi-kassetten-6.webp'
// MULTI KASSETTEN PDF
import multiKassettenPdf from '../assets/downloads/products/multi/Kassetten.pdf'

// MULTI AGGREGATE IMAGES
import multiAggregate1 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-1.webp'
import multiAggregate2 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-2.webp'
import multiAggregate3 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-3.webp'
import multiAggregate4 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-4.webp'
import multiAggregate5 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-5.webp'
import multiAggregate6 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-6.webp'
import multiAggregate7 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-7.webp'
import multiAggregate8 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-8.webp'
import multiAggregate9 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-9.webp'
import multiAggregate10 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-10.webp'
import multiAggregate11 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-11.webp'
import multiAggregate12 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-12.webp'
import multiAggregate13 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-13.webp'
import multiAggregate14 from '../assets/products/Multi-klimaanlagen/Aggregate/multi-aggregate-14.webp'
// MULTI AGGREGATE PDF
import multiAggregatePdf from '../assets/downloads/products/multi/Aggregate.pdf'

// ==============================================================

// KASSETTEN IMAGE
import kassettenImg1 from '../assets/products/Kassetten/kassetten-1.webp'
import kassettenImg2 from '../assets/products/Kassetten/kassetten-2.webp'
import kassettenImg3 from '../assets/products/Kassetten/kassetten-3.webp'
import kassettenImg4 from '../assets/products/Kassetten/kassetten-4.webp'
import kassettenImg5 from '../assets/products/Kassetten/kassetten-5.webp'
import kassettenImg6 from '../assets/products/Kassetten/kassetten-6.webp'
import kassettenImg7 from '../assets/products/Kassetten/kassetten-7.webp'
import kassettenImg8 from '../assets/products/Kassetten/kassetten-8.webp'
// KASETTEN PDF
import kassettenPdf from '../assets/downloads/products/others/kassetten.pdf'

// ==============================================================

// KANALISIERTE IMAGE
import kanalisierteImg1 from '../assets/products/Kanalisierte/kanalisierte-1.webp'
import kanalisierteImg2 from '../assets/products/Kanalisierte/kanalisierte-2.webp'
import kanalisierteImg3 from '../assets/products/Kanalisierte/kanalisierte-3.webp'
import kanalisierteImg4 from '../assets/products/Kanalisierte/kanalisierte-4.webp'
import kanalisierteImg5 from '../assets/products/Kanalisierte/kanalisierte-5.webp'
import kanalisierteImg6 from '../assets/products/Kanalisierte/kanalisierte-6.webp'
import kanalisierteImg7 from '../assets/products/Kanalisierte/kanalisierte-7.webp'
import kanalisierteImg8 from '../assets/products/Kanalisierte/kanalisierte-8.webp'
import kanalisierteImg9 from '../assets/products/Kanalisierte/kanalisierte-9.webp'
import kanalisierteImg10 from '../assets/products/Kanalisierte/kanalisierte-10.webp'
// KANALISIERTE PDF
import kanalisiertePdf from '../assets/downloads/products/others/kanalklima.pdf'

// ==============================================================

// ECOPOWER IMAGE
import ecoPowerImg1 from '../assets/products/Ecopower/Ecopower-1.webp'
import ecoPowerImg2 from '../assets/products/Ecopower/Ecopower-2.webp'
// ECOPOWER PDF
import ecopowerPdf from '../assets/downloads/products/others/ecopower-r290.pdf'

// ==============================================================

// EVIPOWER IMAGE
import eviPowerImg1 from '../assets/products/Evipower/Evipower-1.webp'
import eviPowerImg2 from '../assets/products/Evipower/Evipower-2.webp'
import eviPowerImg3 from '../assets/products/Evipower/Evipower-3.webp'
import eviPowerImg4 from '../assets/products/Evipower/Evipower-4.webp'
import eviPowerImg5 from '../assets/products/Evipower/Evipower-5.webp'
import eviPowerImg6 from '../assets/products/Evipower/Evipower-6.webp'
import eviPowerImg7 from '../assets/products/Evipower/Evipower-7.webp'
import eviPowerImg8 from '../assets/products/Evipower/Evipower-8.webp'
import eviPowerImg9 from '../assets/products/Evipower/Evipower-9.webp'
// EVIPOWER PDF
import evipowerPdf from '../assets/downloads/products/others/evipower.pdf'

// EVIPOWER-PREMIUM IMAGE
import eviPowerPremiumImg1 from '../assets/products/Evipower-premium/evipower_premium1.webp'
import eviPowerPremiumImg2 from '../assets/products/Evipower-premium/evipower_premium2.webp'

// EVIPOWER-PREMIUM PDF
import evipowerPremiumPdf from '../assets/downloads/products/others/evipower-premium.pdf'

// EVIPOWER-ON-OFF IMAGE
import eviPowerOnOffImg1 from '../assets/products/Evipower-ON_OFF/Evipower_onoff-1.webp'
import eviPowerOnOffImg2 from '../assets/products/Evipower-ON_OFF/Evipower_onoff-2.webp'
import eviPowerOnOffImg3 from '../assets/products/Evipower-ON_OFF/Evipower_onoff-3.webp'
import eviPowerOnOffImg4 from '../assets/products/Evipower-ON_OFF/Evipower_onoff-4.webp'
import eviPowerOnOffImg5 from '../assets/products/Evipower-ON_OFF/Evipower_onoff-5.webp'
// EVIPOWER-ON-OFF PDF
import evipowerOnOffPdf from '../assets/downloads/products/others/evipower-onoff.pdf'

// ==============================================================

// WARMEPUMPEN SCHWIMMBAD IMAGE
import pool1 from '../assets/products/pool/heat-pump-pool.png'
import pool2 from '../assets/products/pool/heat-pump-outdoor1.png'
import pool3 from '../assets/products/pool/heat-pump-outdoor2.png'

// WARMEPUMPEN IMAGE
import basicMainImg from '../assets/images/heatpumps.webp'

const products = [
  // ** MAIN CATEGORIE SPLIT **

  // ** SUBCATEGORIE - PARAPHET **
  {
    id: 'CH-S09FVX',
    categorie: 'split',
    subcategorie: 'konsolen',
    title: 'Mini-split system inverter consol series CH-S09FVX - Konsolen',
    tags: 'Mini-split system inverter consol series CH-S09FVX - Konsolen',
    image: konsolenImg1,
    gallery: [
      konsolenImg1,
      konsolenImg2,
      konsolenImg3,
      konsolenImg4,
      konsolenImg5,
      konsolenImg6,
      konsolenImg7,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    description: [
      'Slim size (depth 215 mm)',
      'I Feel function. Temperature sensor in the remote control',
      'Cold air supply in upward direction from the indoor unit',
      'Hot air supply from the bottom side of indoor unit',
      '+8°C button',
      'F-Gas R410A',
      'High energy efficiency class A: EER 3,42; C.O.P. 3,62',
      'New generation DC-inverter rotor compressor',
      'Energy savings up to 59%',
      'Save start at electricity power range from 95 V to 260 V',
      'Stable Frequency Control - smooth and stable operation at compressor frequency ~15 Hz',
      'Noise Analysis Technology - noiseless operation of indoor and outdoor',
      'Energy consumption 1 W in a standby mode',
      'Outdoor temperature range -15°C to +24°C for heating,  -15°C to +48°C for cooling',
      'Precise temperature control, < 0,5°C',
      'Indication of actual indoor and outdoor temperature, indication of indoor temperature',
      'Compressor soft start; electricity current <50A',
      'Energy-saving mode',
      'Automatic air circulation in SWING mode',
      'Turbo Mode button for the fast cooling and heating of the room',
      'Automation operation mode selection: cooling, heating, dehumidifying, ventilation',
      'Delay of operation start to avoid cold air flow',
      'Wide louvers for effective ventilation of the whole room',
      'Self cleaning of indoor unit. Ventilator removes water from heat exchanger after stop of cooling operation. This function prevents growth of bacteria and mold inside indoor units',
      'Bright and clear LED display',
      'Switchable backlight of the indoor unit display',
      'Humidity reduction without temperature decreasing',
      'Comfort mode SLEEP. Noiseless operation during 8 hours',
      'Removable washable prefilter and front panel',
      'On-off timer for 24 hours with 1 minute step',
      'Clocks on remote control',
      'Block function of the remote control',
      'Autorestart with memory of settings',
      'New generation auto defrosting systems Intelligent Preheating. Contrary to traditional defrosting activated regularly after fixed period of time Intelligent Preheating performs defrosting only when it really required. Thus it reduces energy consumption by avoiding unnecessary defrosting cycles.',
      'Anticorrosion finishing of outdoor body',
      'Heat exchanger finishing GREEN-FIN',
      'Self-diagnostic of main functions and modes',
    ],
    cooling: '2.60 (0.45-3.20)',
    heating: '3.30 (0.45-3.75)',
    downloads: paraphetPDF,
  },
  {
    id: 'CH-S12FVX',
    categorie: 'split',
    subcategorie: 'konsolen',
    title: 'Mini-split system inverter consol series CH-S12FVX - Konsolen',
    tags: 'Mini-split system inverter consol series CH-S12FVX - Konsolen',
    image: konsolenImg1,
    gallery: [
      konsolenImg1,
      konsolenImg2,
      konsolenImg3,
      konsolenImg4,
      konsolenImg5,
      konsolenImg6,
      konsolenImg7,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    description: [
      'Slim size (depth 215 mm)',
      'I Feel function. Temperature sensor in the remote control',
      'Cold air supply in upward direction from the indoor unit',
      'Hot air supply from the bottom side of indoor unit',
      '+8°C button',
      'F-Gas R410A',
      'High energy efficiency class A: EER 3,42; C.O.P. 3,62',
      'New generation DC-inverter rotor compressor',
      'Energy savings up to 59%',
      'Save start at electricity power range from 95 V to 260 V',
      'Stable Frequency Control - smooth and stable operation at compressor frequency ~15 Hz',
      'Noise Analysis Technology - noiseless operation of indoor and outdoor',
      'Energy consumption 1 W in a standby mode',
      'Outdoor temperature range -15°C to +24°C for heating,  -15°C to +48°C for cooling',
      'Precise temperature control, < 0,5°C',
      'Indication of actual indoor and outdoor temperature, indication of indoor temperature',
      'Compressor soft start; electricity current <50A',
      'Energy-saving mode',
      'Automatic air circulation in SWING mode',
      'Turbo Mode button for the fast cooling and heating of the room',
      'Automation operation mode selection: cooling, heating, dehumidifying, ventilation',
      'Delay of operation start to avoid cold air flow',
      'Wide louvers for effective ventilation of the whole room',
      'Self cleaning of indoor unit. Ventilator removes water from heat exchanger after stop of cooling operation. This function prevents growth of bacteria and mold inside indoor units',
      'Bright and clear LED display',
      'Switchable backlight of the indoor unit display',
      'Humidity reduction without temperature decreasing',
      'Comfort mode SLEEP. Noiseless operation during 8 hours',
      'Removable washable prefilter and front panel',
      'On-off timer for 24 hours with 1 minute step',
      'Clocks on remote control',
      'Block function of the remote control',
      'Autorestart with memory of settings',
      'New generation auto defrosting systems Intelligent Preheating. Contrary to traditional defrosting activated regularly after fixed period of time Intelligent Preheating performs defrosting only when it really required. Thus it reduces energy consumption by avoiding unnecessary defrosting cycles.',
      'Anticorrosion finishing of outdoor body',
      'Heat exchanger finishing GREEN-FIN',
      'Self-diagnostic of main functions and modes',
    ],
    cooling: '3.52 (0.60-3.95) kW',
    heating: '4.00 (0.60-4.70) kW',
    downloads: paraphetPDF,
  },
  {
    id: 'CH-S18FVX',
    categorie: 'split',
    subcategorie: 'konsolen',
    title: 'Mini-split system inverter consol series CH-S18FVX - Konsolen',
    tags: 'Mini-split system inverter consol series CH-S18FVX - Konsolen',
    image: konsolenImg1,
    gallery: [
      konsolenImg1,
      konsolenImg2,
      konsolenImg3,
      konsolenImg4,
      konsolenImg5,
      konsolenImg6,
      konsolenImg7,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    description: [
      'Slim size (depth 215 mm)',
      'I Feel function. Temperature sensor in the remote control',
      'Cold air supply in upward direction from the indoor unit',
      'Hot air supply from the bottom side of indoor unit',
      '+8°C button',
      'F-Gas R410A',
      'High energy efficiency class A: EER 3,42; C.O.P. 3,62',
      'New generation DC-inverter rotor compressor',
      'Energy savings up to 59%',
      'Save start at electricity power range from 95 V to 260 V',
      'Stable Frequency Control - smooth and stable operation at compressor frequency ~15 Hz',
      'Noise Analysis Technology - noiseless operation of indoor and outdoor',
      'Energy consumption 1 W in a standby mode',
      'Outdoor temperature range -15°C to +24°C for heating,  -15°C to +48°C for cooling',
      'Precise temperature control, < 0,5°C',
      'Indication of actual indoor and outdoor temperature, indication of indoor temperature',
      'Compressor soft start; electricity current <50A',
      'Energy-saving mode',
      'Automatic air circulation in SWING mode',
      'Turbo Mode button for the fast cooling and heating of the room',
      'Automation operation mode selection: cooling, heating, dehumidifying, ventilation',
      'Delay of operation start to avoid cold air flow',
      'Wide louvers for effective ventilation of the whole room',
      'Self cleaning of indoor unit. Ventilator removes water from heat exchanger after stop of cooling operation. This function prevents growth of bacteria and mold inside indoor units',
      'Bright and clear LED display',
      'Switchable backlight of the indoor unit display',
      'Humidity reduction without temperature decreasing',
      'Comfort mode SLEEP. Noiseless operation during 8 hours',
      'Removable washable prefilter and front panel',
      'On-off timer for 24 hours with 1 minute step',
      'Clocks on remote control',
      'Block function of the remote control',
      'Autorestart with memory of settings',
      'New generation auto defrosting systems Intelligent Preheating. Contrary to traditional defrosting activated regularly after fixed period of time Intelligent Preheating performs defrosting only when it really required. Thus it reduces energy consumption by avoiding unnecessary defrosting cycles.',
      'Anticorrosion finishing of outdoor body',
      'Heat exchanger finishing GREEN-FIN',
      'Self-diagnostic of main functions and modes',
    ],
    cooling: '5.27 (0.90-5.60) kW',
    heating: '5.50 (0.90-6.60) kW',
    downloads: paraphetPDF,
  },
  // ** SUBCATEGORIE - ALPHA **
  {
    id: 'CH-S09FTXE-NG',
    categorie: 'split',
    subcategorie: 'Alpha',
    title:
      'Cooper&Hunter Alpha CH-S09FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Alpha CH-S09FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: alphaImg1,
    gallery: [
      alphaImg1,
      alphaImg2,
      alphaImg3,
      alphaImg4,
      alphaImg5,
      alphaImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Alpha - die neueste Version der bei Kunden beliebten Klimaanlage, jetzt mit dem ökologischen Kältemittel R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY und einem Plasma-Ionisator ausgestattet, und die Steuerung wird durch das eingebaute WiFi-Modul erleichtert. Ein zusätzlicher Vorteil ist die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    cooling: '',
    heating: '',
    downloads: alphaPDF,
  },
  {
    id: 'CH-S12FTXE-NG',
    categorie: 'split',
    subcategorie: 'Alpha',
    title:
      'Cooper&Hunter Alpha CH-S12FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Alpha CH-S12FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: alphaImg1,
    gallery: [
      alphaImg1,
      alphaImg2,
      alphaImg3,
      alphaImg4,
      alphaImg5,
      alphaImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Alpha - die neueste Version der bei Kunden beliebten Klimaanlage, jetzt mit dem ökologischen Kältemittel R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY und einem Plasma-Ionisator ausgestattet, und die Steuerung wird durch das eingebaute WiFi-Modul erleichtert. Ein zusätzlicher Vorteil ist die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: alphaPDF,
  },
  {
    id: 'CH-S18FTXE-NG',
    categorie: 'split',
    subcategorie: 'Alpha',
    title:
      'Cooper&Hunter Alpha CH-S18FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Alpha CH-S18FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: alphaImg1,
    gallery: [
      alphaImg1,
      alphaImg2,
      alphaImg3,
      alphaImg4,
      alphaImg5,
      alphaImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Alpha - die neueste Version der bei Kunden beliebten Klimaanlage, jetzt mit dem ökologischen Kältemittel R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY und einem Plasma-Ionisator ausgestattet, und die Steuerung wird durch das eingebaute WiFi-Modul erleichtert. Ein zusätzlicher Vorteil ist die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: alphaPDF,
  },
  {
    id: 'CH-S24FTXE-NG',
    categorie: 'split',
    subcategorie: 'Alpha',
    title:
      'Cooper&Hunter Alpha CH-S24FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Alpha CH-S24FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: alphaImg1,
    gallery: [
      alphaImg1,
      alphaImg2,
      alphaImg3,
      alphaImg4,
      alphaImg5,
      alphaImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Alpha - die neueste Version der bei Kunden beliebten Klimaanlage, jetzt mit dem ökologischen Kältemittel R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY und einem Plasma-Ionisator ausgestattet, und die Steuerung wird durch das eingebaute WiFi-Modul erleichtert. Ein zusätzlicher Vorteil ist die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: alphaPDF,
  },
  // ** SUBCATEGORIE - ARCTIC **
  {
    id: 'CH-S09FTXLA-NG',
    categorie: 'split',
    subcategorie: 'Arctic',
    title:
      'Cooper&Hunter Arctic CH-S09FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Arctic CH-S09FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: arcticImg1,
    gallery: [
      arcticImg1,
      arcticImg2,
      arcticImg3,
      arcticImg4,
      arcticImg5,
      arcticImg6,
      arcticImg7,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Arctic Inverter mit Verwendung des ökologischen Kältemittels R32 wurde entwickelt, um im Heizmodus bei niedrigen Außentemperaturen bis zu -25 ° C zu arbeiten. Das Gerät ist mit einer Auffangwannenheizung, einer Kurbelwannenheizung und einem Luftionisator ausgestattet. Die Steuerung wird durch das eingebaute WiFi-Modul erleichtert.',
    description: [
      'Effektiver Betriebstemperaturbereich von -25 °C bis +48 °C',
      'G-Matrik-Technologie - reibungsloser und stabiler Kompressorbetrieb bei sehr niedrigen Frequenzen',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleisten kann',
      'Trockenkontakt - Die Grundfunktion ist die Fähigkeit, die Klimaanlage mit einem externen Signal ein- und auszuschalten. Es bietet auch die Möglichkeit, das Betriebs- und Störungssignal des Geräts auszugeben',
      'Plasma-Ionisator',
      'Horizontale und vertikale Bewegung der Lamellen für eine bessere Luftverteilung im Raum',
      'Neuer Fahrer mit einer Klappe',
      'Eingebauter Temperatursensor in der Fernbedienung',
    ],
    downloads: arcticPDF,
  },
  {
    id: 'CH-S12FTXLA-NG',
    categorie: 'split',
    subcategorie: 'Arctic',
    title:
      'Cooper&Hunter Arctic CH-S12FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Arctic CH-S12FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: arcticImg1,
    gallery: [
      arcticImg1,
      arcticImg2,
      arcticImg3,
      arcticImg4,
      arcticImg5,
      arcticImg6,
      arcticImg7,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Arctic Inverter mit Verwendung des ökologischen Kältemittels R32 wurde entwickelt, um im Heizmodus bei niedrigen Außentemperaturen bis zu -25 ° C zu arbeiten. Das Gerät ist mit einer Auffangwannenheizung, einer Kurbelwannenheizung und einem Luftionisator ausgestattet. Die Steuerung wird durch das eingebaute WiFi-Modul erleichtert.',
    description: [
      'Effektiver Betriebstemperaturbereich von -25 °C bis +48 °C',
      'G-Matrik-Technologie - reibungsloser und stabiler Kompressorbetrieb bei sehr niedrigen Frequenzen',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleisten kann',
      'Trockenkontakt - Die Grundfunktion ist die Fähigkeit, die Klimaanlage mit einem externen Signal ein- und auszuschalten. Es bietet auch die Möglichkeit, das Betriebs- und Störungssignal des Geräts auszugeben',
      'Plasma-Ionisator',
      'Horizontale und vertikale Bewegung der Lamellen für eine bessere Luftverteilung im Raum',
      'Neuer Fahrer mit einer Klappe',
      'Eingebauter Temperatursensor in der Fernbedienung',
    ],
    downloads: arcticPDF,
  },
  {
    id: 'CH-S18FTXLA-NG',
    categorie: 'split',
    subcategorie: 'Arctic',
    title:
      'Cooper&Hunter Arctic CH-S18FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Arctic CH-S18FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: arcticImg1,
    gallery: [
      arcticImg1,
      arcticImg2,
      arcticImg3,
      arcticImg4,
      arcticImg5,
      arcticImg6,
      arcticImg7,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Arctic Inverter mit Verwendung des ökologischen Kältemittels R32 wurde entwickelt, um im Heizmodus bei niedrigen Außentemperaturen bis zu -25 ° C zu arbeiten. Das Gerät ist mit einer Auffangwannenheizung, einer Kurbelwannenheizung und einem Luftionisator ausgestattet. Die Steuerung wird durch das eingebaute WiFi-Modul erleichtert.',
    description: [
      'Effektiver Betriebstemperaturbereich von -25 °C bis +48 °C',
      'G-Matrik-Technologie - reibungsloser und stabiler Kompressorbetrieb bei sehr niedrigen Frequenzen',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleisten kann',
      'Trockenkontakt - Die Grundfunktion ist die Fähigkeit, die Klimaanlage mit einem externen Signal ein- und auszuschalten. Es bietet auch die Möglichkeit, das Betriebs- und Störungssignal des Geräts auszugeben',
      'Plasma-Ionisator',
      'Horizontale und vertikale Bewegung der Lamellen für eine bessere Luftverteilung im Raum',
      'Neuer Fahrer mit einer Klappe',
      'Eingebauter Temperatursensor in der Fernbedienung',
    ],
    downloads: arcticPDF,
  },
  {
    id: 'CH-S24FTXLA-NG',
    categorie: 'split',
    subcategorie: 'Arctic',
    title:
      'Cooper&Hunter Arctic CH-S24FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Arctic CH-S24FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: arcticImg1,
    gallery: [
      arcticImg1,
      arcticImg2,
      arcticImg3,
      arcticImg4,
      arcticImg5,
      arcticImg6,
      arcticImg7,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Arctic Inverter mit Verwendung des ökologischen Kältemittels R32 wurde entwickelt, um im Heizmodus bei niedrigen Außentemperaturen bis zu -25 ° C zu arbeiten. Das Gerät ist mit einer Auffangwannenheizung, einer Kurbelwannenheizung und einem Luftionisator ausgestattet. Die Steuerung wird durch das eingebaute WiFi-Modul erleichtert.',
    description: [
      'Effektiver Betriebstemperaturbereich von -25 °C bis +48 °C',
      'G-Matrik-Technologie - reibungsloser und stabiler Kompressorbetrieb bei sehr niedrigen Frequenzen',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleisten kann',
      'Trockenkontakt - Die Grundfunktion ist die Fähigkeit, die Klimaanlage mit einem externen Signal ein- und auszuschalten. Es bietet auch die Möglichkeit, das Betriebs- und Störungssignal des Geräts auszugeben',
      'Plasma-Ionisator',
      'Horizontale und vertikale Bewegung der Lamellen für eine bessere Luftverteilung im Raum',
      'Neuer Fahrer mit einer Klappe',
      'Eingebauter Temperatursensor in der Fernbedienung',
    ],
    downloads: arcticPDF,
  },
  // ** SUBCATEGORIE - VERITAS **
  {
    id: 'CH-S09FTXQ-NG',
    categorie: 'split',
    subcategorie: 'Veritas',
    title:
      'Cooper&Hunter Veritas CH-S09FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Veritas CH-S09FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: veritasImg1,
    gallery: [
      veritasImg1,
      veritasImg2,
      veritasImg3,
      veritasImg4,
      veritasImg5,
      veritasImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: veritasPDF,
  },
  {
    id: 'CH-S12FTXQ-NG',
    categorie: 'split',
    subcategorie: 'Veritas',
    title:
      'Cooper&Hunter Veritas CH-S12FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Veritas CH-S12FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: veritasImg1,
    gallery: [
      veritasImg1,
      veritasImg2,
      veritasImg3,
      veritasImg4,
      veritasImg5,
      veritasImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: veritasPDF,
  },
  {
    id: 'CH-S18FTXQ-NG',
    categorie: 'split',
    subcategorie: 'Veritas',
    title:
      'Cooper&Hunter Veritas CH-S18FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Veritas CH-S18FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: veritasImg1,
    gallery: [
      veritasImg1,
      veritasImg2,
      veritasImg3,
      veritasImg4,
      veritasImg5,
      veritasImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: veritasPDF,
  },
  {
    id: 'CH-S24FTXL2Q-NG',
    categorie: 'split',
    subcategorie: 'Veritas',
    title:
      'Cooper&Hunter Veritas CH-S24FTXL2Q-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Veritas CH-S24FTXL2Q-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: veritasImg1,
    gallery: [
      veritasImg1,
      veritasImg2,
      veritasImg3,
      veritasImg4,
      veritasImg5,
      veritasImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: veritasPDF,
  },
  // ** SUBCATEGORIE - SUPREME KONTINENTAL **
  {
    id: 'CH-S09FTXAL',
    categorie: 'split',
    subcategorie: 'Supreme Kontinental',
    title: 'Cooper&Hunter CH-S09FTXAL-WP/SC/GD/BL SUPREME',
    tags: 'Cooper&Hunter CH-S09FTXAL-WP/SC/GD/BL  SUPREME, kontinental',
    image: supremeImg1,
    gallery: [
      supremeImg1,
      supremeImg2,
      supremeImg3,
      supremeImg4,
      supremeImg5,
      supremeImg6,
      supremeImg7,
      supremeImg8,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      '+8 Grad Funktion',
      'Vertikale und horizontale Bewegung der Lamellen',
      'Trockener Kontakt',
      '7 Lüftergeschwindigkeiten',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleistet',
    ],
    downloads: supremeKontPDF,
  },
  {
    id: 'CH-S12FTXAL',
    categorie: 'split',
    subcategorie: 'Supreme Kontinental',
    title: 'Cooper&Hunter CH-S12FTXAL-WP/SC/GD/BL SUPREME',
    tags: 'Cooper&Hunter CH-S12FTXAL-WP/SC/GD/BL  SUPREME, kontinental',
    image: supremeImg1,
    gallery: [
      supremeImg1,
      supremeImg2,
      supremeImg3,
      supremeImg4,
      supremeImg5,
      supremeImg6,
      supremeImg7,
      supremeImg8,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      '+8 Grad Funktion',
      'Vertikale und horizontale Bewegung der Lamellen',
      'Trockener Kontakt',
      '7 Lüftergeschwindigkeiten',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleistet',
    ],
    downloads: supremeKontPDF,
  },
  {
    id: 'CH-S18FTXAL',
    categorie: 'split',
    subcategorie: 'Supreme Kontinental',
    title: 'Cooper&Hunter CH-S18FTXAL-WP/SC/GD/BL SUPREME',
    tags: 'Cooper&Hunter CH-S18FTXAL-WP/SC/GD/BL  SUPREME, kontinental',
    image: supremeImg1,
    gallery: [
      supremeImg1,
      supremeImg2,
      supremeImg3,
      supremeImg4,
      supremeImg5,
      supremeImg6,
      supremeImg7,
      supremeImg8,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      '+8 Grad Funktion',
      'Vertikale und horizontale Bewegung der Lamellen',
      'Trockener Kontakt',
      '7 Lüftergeschwindigkeiten',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleistet',
    ],
    downloads: supremeKontPDF,
  },
  {
    id: 'CH-S24FTXAL',
    categorie: 'split',
    subcategorie: 'Supreme Kontinental',
    title: 'Cooper&Hunter CH-S24FTXAL-WP/SC/GD/BL SUPREME',
    tags: 'Cooper&Hunter CH-S24FTXAL-WP/SC/GD/BL  SUPREME, kontinental',
    image: supremeImg1,
    gallery: [
      supremeImg1,
      supremeImg2,
      supremeImg3,
      supremeImg4,
      supremeImg5,
      supremeImg6,
      supremeImg7,
      supremeImg8,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      '+8 Grad Funktion',
      'Vertikale und horizontale Bewegung der Lamellen',
      'Trockener Kontakt',
      '7 Lüftergeschwindigkeiten',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleistet',
    ],
    downloads: supremeKontPDF,
  },
  // ** SUBCATEGORIE - NORDIC EVO 2 **
  {
    id: 'CH-S09FTXN-E2WF',
    categorie: 'split',
    subcategorie: 'Nordic Evo 2',
    title:
      'Cooper&Hunter Nordic CH-S09FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S09FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, evo, evo 2',
    image: nordicImg1,
    gallery: [
      nordicImg1,
      nordicImg2,
      nordicImg3,
      nordicImg4,
      nordicImg5,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Nordic Evo 2 ist eine Klimaanlage zum Heizen von Gebäuden. Ein spezieller Algorithmus sorgt für einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen. Das Gerät nutzt u.a bequem zu bedienender, stufenloser Lüfter und Ionisator, der Staub und Bakterien aus der Luft entfernt.',
    description: [
      'Entwickelt, um bei niedrigen Temperaturen zu arbeiten',
      'Serienmäßig eingebautes WLAN-Modul',
      'Kompressorheizung und Auffangwannenheizung',
      'Hochwertiger Luftionisator',
      'I-Action-Technologie: sanfte Kompressorsteuerung mit einer Frequenz von bis zu 1 Hz',
    ],
    downloads: nordicPDF,
  },
  {
    id: 'CH-S12FTXN-E2WF',
    categorie: 'split',
    subcategorie: 'Nordic Evo 2',
    title:
      'Cooper&Hunter Nordic CH-S12FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S12FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, evo, evo 2',
    image: nordicImg1,
    gallery: [
      nordicImg1,
      nordicImg2,
      nordicImg3,
      nordicImg4,
      nordicImg5,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Nordic Evo 2 ist eine Klimaanlage zum Heizen von Gebäuden. Ein spezieller Algorithmus sorgt für einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen. Das Gerät nutzt u.a bequem zu bedienender, stufenloser Lüfter und Ionisator, der Staub und Bakterien aus der Luft entfernt.',
    description: [
      'Entwickelt, um bei niedrigen Temperaturen zu arbeiten',
      'Serienmäßig eingebautes WLAN-Modul',
      'Kompressorheizung und Auffangwannenheizung',
      'Hochwertiger Luftionisator',
      'I-Action-Technologie: sanfte Kompressorsteuerung mit einer Frequenz von bis zu 1 Hz',
    ],
    downloads: nordicPDF,
  },
  {
    id: 'CH18FTXN-E2WF',
    categorie: 'split',
    subcategorie: 'Nordic Evo 2',
    title:
      'Cooper&Hunter Nordic CH-S18FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S18FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, evo, evo 2',
    image: nordicImg1,
    gallery: [
      nordicImg1,
      nordicImg2,
      nordicImg3,
      nordicImg4,
      nordicImg5,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Nordic Evo 2 ist eine Klimaanlage zum Heizen von Gebäuden. Ein spezieller Algorithmus sorgt für einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen. Das Gerät nutzt u.a bequem zu bedienender, stufenloser Lüfter und Ionisator, der Staub und Bakterien aus der Luft entfernt.',
    description: [
      'Entwickelt, um bei niedrigen Temperaturen zu arbeiten',
      'Serienmäßig eingebautes WLAN-Modul',
      'Kompressorheizung und Auffangwannenheizung',
      'Hochwertiger Luftionisator',
      'I-Action-Technologie: sanfte Kompressorsteuerung mit einer Frequenz von bis zu 1 Hz',
    ],
    downloads: nordicPDF,
  },
  {
    id: 'CH24FTXN-E2WF',
    categorie: 'split',
    subcategorie: 'Nordic Evo 2',
    title:
      'Cooper&Hunter Nordic CH24FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH24FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, evo, evo 2',
    image: nordicImg1,
    gallery: [
      nordicImg1,
      nordicImg2,
      nordicImg3,
      nordicImg4,
      nordicImg5,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Nordic Evo 2 ist eine Klimaanlage zum Heizen von Gebäuden. Ein spezieller Algorithmus sorgt für einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen. Das Gerät nutzt u.a bequem zu bedienender, stufenloser Lüfter und Ionisator, der Staub und Bakterien aus der Luft entfernt.',
    description: [
      'Entwickelt, um bei niedrigen Temperaturen zu arbeiten',
      'Serienmäßig eingebautes WLAN-Modul',
      'Kompressorheizung und Auffangwannenheizung',
      'Hochwertiger Luftionisator',
      'I-Action-Technologie: sanfte Kompressorsteuerung mit einer Frequenz von bis zu 1 Hz',
    ],
    downloads: nordicPDF,
  },
  // ** SUBCATEGORIE - VITAL **
  {
    id: 'CH-S09FTXF-NG',
    categorie: 'split',
    subcategorie: 'Vital',
    title:
      'Cooper&Hunter Nordic CH-S09FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S09FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, vital',
    image: vitalImg1,
    gallery: [
      vitalImg1,
      vitalImg2,
      vitalImg3,
      vitalImg4,
      vitalImg5,
      vitalImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von - 15°C bis + 43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung.',
    ],
    downloads: vitalPDF,
  },
  {
    id: 'CH-S12FTXF-NG',
    categorie: 'split',
    subcategorie: 'Vital',
    title:
      'Cooper&Hunter Nordic CH-S12FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S12FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, vital',
    image: vitalImg1,
    gallery: [
      vitalImg1,
      vitalImg2,
      vitalImg3,
      vitalImg4,
      vitalImg5,
      vitalImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von - 15°C bis + 43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung.',
    ],
    downloads: vitalPDF,
  },
  {
    id: 'CH-S18FTXF-NG',
    categorie: 'split',
    subcategorie: 'Vital',
    title:
      'Cooper&Hunter Nordic CH-S18FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S18FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, vital',
    image: vitalImg1,
    gallery: [
      vitalImg1,
      vitalImg2,
      vitalImg3,
      vitalImg4,
      vitalImg5,
      vitalImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von - 15°C bis + 43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung.',
    ],
    downloads: vitalPDF,
  },
  {
    id: 'CH-S24FTXF-NG',
    categorie: 'split',
    subcategorie: 'Vital',
    title:
      'Cooper&Hunter Nordic CH-S24FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S24FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, vital',
    image: vitalImg1,
    gallery: [
      vitalImg1,
      vitalImg2,
      vitalImg3,
      vitalImg4,
      vitalImg5,
      vitalImg6,
      outdoor1,
      outdoor2,
      outdoor3,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von - 15°C bis + 43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung.',
    ],
    downloads: vitalPDF,
  },

  // ** MAIN CATEGORIE MULTI KLIMAANLAGEN **

  // ALPHA - MULTI
  {
    id: 'alpha',
    categorie: 'multi-systeme',
    subcategorie: 'multi-Systeme',
    title: 'Cooper&Hunter Multi Klimaanlagen - Alpha serie',
    tags: 'Cooper&Hunter, Alpha, Multi, CH-S07FTXE-NG (I), CH-S09FTXE-NG (I), CH-S12FTXE-NG (I), CH-S18FTXE-NG (I), CH-S24FTXE-NG (I),',
    image: multiAlpha1,
    gallery: [
      multiAlpha1,
      multiAlpha2,
      multiAlpha3,
      multiAlpha4,
      multiAlpha5,
      multiAlpha6,
    ],
    introduction:
      'ALPHA - eine einfach zu bedienende, zuverlässige und bis ins kleinste Detail durchdachte Klimaanlage, die bei unseren klimatischen Bedingungen Energie spart. Alle Technologien, die bei der Herstellung von Geräten der Premiumklasse verwendet werden, wurden in der ALPHA-Klimaanlage verwendet',
    description: [
      'Plasma-Ionisator',
      'ECO-Option im Heizmodus',
      'Energiesparfunktion für Kühlbetrieb',
      'ICH FÜHLE Funktion',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-S07FTXE-NG (I)' },
      { name: 'CH-S09FTXE-NG (I)' },
      { name: 'CH-S12FTXE-NG (I)' },
      { name: 'CH-S18FTXE-NG (I)' },
      { name: 'CH-S24FTXE-NG (I)' },
    ],
    downloads: multiAlphaPdf,
  },

  // VERITAS - MULTI
  {
    id: 'veritas',
    categorie: 'multi-systeme',
    subcategorie: 'multi-Systeme',
    title: 'Cooper&Hunter Multi Klimaanlagen - Veritas serie',
    tags: 'Cooper&Hunter, Veritas, Multi, CH-S07FTXQ-NG (I), CH-S09FTXQ-NG (I), CH-S12FTXQ-NG (I), CH-S18FTXQ-NG (I), CH-S24FTXQ-NG (I),',
    image: multiVeritas1,
    gallery: [multiVeritas1, multiVeritas2, multiVeritas3, multiVeritas4],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-S07FTXQ-NG (I)' },
      { name: 'CH-S09FTXQ-NG (I)' },
      { name: 'CH-S12FTXQ-NG (I)' },
      { name: 'CH-S18FTXQ-NG (I)' },
      { name: 'CH-S24FTXQ-NG (I)' },
    ],
    downloads: multiVeritasPdf,
  },

  // VITAL - MULTI
  {
    id: 'vital',
    categorie: 'multi-systeme',
    subcategorie: 'multi-Systeme',
    title: 'Cooper&Hunter Multi Klimaanlagen - Vital serie',
    tags: 'Cooper&Hunter, Vital, Multi, CH-S09FTXF-NG (I), CH-S12FTXF-NG (I), CH-S18FTXF-NG (I), CH-S24FTXF-NG (I),',
    image: multiVital1,
    gallery: [
      multiVital1,
      multiVital2,
      multiVital3,
      multiVital4,
      multiVital5,
      multiVital6,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von -15°C bis +43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht ',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-S09FTXF-NG (I)' },
      { name: 'CH-S12FTXF-NG (I)' },
      { name: 'CH-S18FTXF-NG (I)' },
      { name: 'CH-S24FTXF-NG (I)' },
    ],
    downloads: multiVitalPdf,
  },

  // LEITUNG - MULTI
  {
    id: 'kanalklimageräte',
    categorie: 'multi-systeme',
    subcategorie: 'multi-Systeme',
    title: 'Cooper&Hunter Multi Klimaanlagen - Kanalklimageräte',
    tags: 'Cooper&Hunter, Kanalklimageräte, Multi, CHML-ID09RK, CHML-ID12RK, CHML-ID18RK, CHML-ID24RK',
    image: multiLeitung1,
    gallery: [
      multiLeitung1,
      multiLeitung2,
      multiLeitung3,
      multiLeitung4,
      multiLeitung5,
    ],
    introduction:
      'C&H Multi-Systeme mit Inverter-Technologie sind die fortschrittlichsten Wärmepumpen. Die Geräte bieten den Komfort von Heizen und Kühlen in bis zu 5 separaten Zonen, was sie zu einer idealen Lösung für Wohn- und Gewerbegebäude macht.',
    description: [
      'Automatischer Neustart',
      'Energie sparen',
      'Flexible Installation in verschiedenen Räumen',
      'Niedriger Geräuschpegel',
      'Leicht zusammenzubauen',
      '3 Lüftergeschwindigkeiten',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CHML-ID09RK' },
      { name: 'CHML-ID12RK' },
      { name: 'CHML-ID18RK' },
      { name: 'CHML-ID24RK' },
    ],
    downloads: multiKanalklimaPdf,
  },

  // AGGREGATE - MULTI

  {
    id: 'aggregate',
    categorie: 'multi-systeme',
    subcategorie: 'multi-Systeme',
    title: 'Cooper&Hunter Multi Klimaanlagen - AGGREGATE',
    tags: 'Cooper&Hunter, Aggregate, Multi, CHML-U14RK2, CHML-U18RK2, CHML-U21RK3, CHML-U24RK3, CHML-U28RK4, CHML-U36RK4, CHML-U42RK5',
    image: multiAggregate1,
    gallery: [
      multiAggregate1,
      multiAggregate2,
      multiAggregate3,
      multiAggregate4,
      multiAggregate5,
      multiAggregate6,
      multiAggregate7,
      multiAggregate8,
      multiAggregate9,
      multiAggregate10,
      multiAggregate11,
      multiAggregate12,
      multiAggregate13,
      multiAggregate14,
    ],
    introduction:
      'Jedes Außengerät des Multi-Systems ist mit einem Inverter-Kompressor mit variabler Frequenz ausgestattet. Diese Gerätetypen können bis zu 5 Räume heizen oder kühlen, ohne dass ein Verteiler benötigt wird. Unsere G-Matrik-Technologie spart Energie und reduziert Außengeräusche.',
    description: [
      'Hohe Effizienz - hohe Effizienz und Wirtschaftlichkeit im Einsatz',
      'Intelligentes Abtausystem',
      '24-Stunden-Timer - die Möglichkeit, den Betrieb des Geräts zu programmieren',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CHML-U14RK2' },
      { name: 'CHML-U18RK2' },
      { name: 'CHML-U21RK3' },
      { name: 'CHML-U24RK3' },
      { name: 'CHML-U28RK4' },
      { name: 'CHML-U36RK4' },
      { name: 'CHML-U42RK5' },
    ],
    downloads: multiAggregatePdf,
  },

  // KASSETTEN - MULTI
  {
    id: 'kassetten',
    categorie: 'multi-systeme',
    subcategorie: 'multi-Systeme',
    title: 'Cooper&Hunter Multi Klimaanlagen - KASETTEN',
    tags: 'Cooper&Hunter, Kassetten, Multi, CHML-IC12RK, CHML-IC18RK, CHML-IC24RK',
    image: multiKassetten1,
    gallery: [
      multiKassetten1,
      multiKassetten2,
      multiKassetten3,
      multiKassetten4,
      multiKassetten5,
      multiKassetten6,
    ],
    introduction:
      'C&H Multi-Systeme mit Inverter-Technologie sind die fortschrittlichsten Wärmepumpen. Die Geräte bieten den Komfort von Heizen und Kühlen in bis zu 5 separaten Zonen, was sie zu einer idealen Lösung für Wohn- und Gewerbegebäude macht.',
    description: [
      'Automatischer Neustart',
      'Intelligentes Abtauen',
      'Kompressorschutz',
      'Energie sparen',
      'Niedriger Geräuschpegel',
      'Leicht zusammenzubauen',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CHML-IC12RK' },
      { name: 'CHML-IC18RK' },
      { name: 'CHML-IC24RK' },
    ],
    downloads: multiKassettenPdf,
  },

  // SUPREME - MULTI
  {
    id: 'supreme',
    categorie: 'multi-systeme',
    subcategorie: 'multi-Systeme',
    title: 'Cooper&Hunter Multi Klimaanlagen - Supreme serie',
    tags: 'Cooper&Hunter, Supreme, Multi, CH-S09FTXAM2S-SC / GD / BL (I), CH-S12FTXAM2S-SC / GD / BL (I), CH-S18FTXAM2S-SC / GD / BL (I), CH-S24FTXAM2S-SC / GD / BL (I)',
    image: multiSupreme1,
    gallery: [
      multiSupreme1,
      multiSupreme2,
      multiSupreme3,
      multiSupreme4,
      multiSupreme5,
      multiSupreme6,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      'ECO-Option im Heizmodus',
      'Energiesparfunktion für Kühlbetrieb',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-S09FTXAM2S-SC / GD / BL (I)' },
      { name: 'CH-S12FTXAM2S-SC / GD / BL (I)' },
      { name: 'CH-S18FTXAM2S-SC / GD / BL (I)' },
      { name: 'CH-S24FTXAM2S-SC / GD / BL (I)' },
    ],
    downloads: multiSupremePdf,
  },

  // KONSOLEN - MULTI
  {
    id: 'konsolen',
    categorie: 'multi-systeme',
    subcategorie: 'multi-Systeme',
    title: 'Cooper&Hunter Multi Klimaanlagen - Konsolen serie',
    tags: 'Cooper&Hunter, Konsolen, Multi, CHML-IK09RK, CHML-IK12RK, CHML-IK18RK',
    image: multiKonsolen1,
    gallery: [
      multiKonsolen1,
      multiKonsolen2,
      multiKonsolen3,
      multiKonsolen4,
      multiKonsolen5,
      multiKonsolen6,
      multiKonsolen7,
    ],
    introduction:
      'C&H Multi-Systeme mit Inverter-Technologie sind die fortschrittlichsten Wärmepumpen. Die Geräte bieten den Komfort von Heizen und Kühlen in bis zu 5 separaten Zonen, was sie zu einer idealen Lösung für Wohn- und Gewerbegebäude macht.',
    description: [
      'Automatischer Neustart',
      'Energie sparen',
      'Niedriger Geräuschpegel',
      'Leicht zusammenzubauen',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CHML-IK09RK' },
      { name: 'CHML-IK12RK' },
      { name: 'CHML-IK18RK' },
    ],
    downloads: multiKonsolenPdf,
  },

  // KANALISIERTE
  {
    id: 'CH-IDS035PRK | CH-IU035RK',
    categorie: 'kanalisierte klimaanlagen',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IDS035PRK/CH-IU035RK Kanalisierte indoor/outdoor',
    tags: 'Cooper&Hunter CH-IDS035PRK/CH-IU035RK Kanalisierte indoor/outdoor',
    image: kanalisierteImg1,
    gallery: [
      kanalisierteImg1,
      kanalisierteImg2,
      kanalisierteImg3,
      kanalisierteImg4,
      kanalisierteImg5,
      kanalisierteImg6,
      kanalisierteImg7,
      kanalisierteImg8,
      kanalisierteImg9,
      kanalisierteImg10,
    ],
    introduction:
      'Kanalklimageräte sind für den Einbau im Deckenraum konzipiert. Sie sind eine ideale Lösung für große Hotelräume, Restaurants oder Hörsäle. Der Vorteil dieses Systems ist die Möglichkeit der Luftverteilung durch Kanäle und die Gestaltung von Luftöffnungen an beliebiger Stelle in der Decke.',
    description: [
      '+8 Grad Funktion im Heizbetrieb',
      'Schaukelfunktion für Modelle 035-071',
      'DC-Inverter-Lüftermotoren',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      'Möglichkeit, den externen statischen Druck je nach Bedarf anzupassen',
      'Modelle 035 und 050 nur 20 cm hoch',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IDS035PRK/CH-IU035RK' },
      { name: 'CH-IDS050PRK/CH-IU050RK' },
      { name: 'CH-IDS071PRK/CH-IU071RK' },
      { name: 'CH-IDH100PRK/CH-IU100RM' },
      { name: 'CH-IDH140PRK/CH-IU140RM' },
      { name: 'CH-IDH160PRK/CH-IU160RM' },
    ],
    downloads: kanalisiertePdf,
  },
  {
    id: 'CH-IDS050PRK | CH-IU050RK',
    categorie: 'kanalisierte klimaanlagen',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IDS050PRK/CH-IU050RK Kanalisierte indoor/outdoor',
    tags: 'Cooper&Hunter CH-IDS050PRK/CH-IU050RK Kanalisierte indoor/outdoor',
    image: kanalisierteImg1,
    gallery: [
      kanalisierteImg1,
      kanalisierteImg2,
      kanalisierteImg3,
      kanalisierteImg4,
      kanalisierteImg5,
      kanalisierteImg6,
      kanalisierteImg7,
      kanalisierteImg8,
      kanalisierteImg9,
      kanalisierteImg10,
    ],
    introduction:
      'Kanalklimageräte sind für den Einbau im Deckenraum konzipiert. Sie sind eine ideale Lösung für große Hotelräume, Restaurants oder Hörsäle. Der Vorteil dieses Systems ist die Möglichkeit der Luftverteilung durch Kanäle und die Gestaltung von Luftöffnungen an beliebiger Stelle in der Decke.',
    description: [
      '+8 Grad Funktion im Heizbetrieb',
      'Schaukelfunktion für Modelle 035-071',
      'DC-Inverter-Lüftermotoren',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      'Möglichkeit, den externen statischen Druck je nach Bedarf anzupassen',
      'Modelle 035 und 050 nur 20 cm hoch',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IDS035PRK/CH-IU035RK' },
      { name: 'CH-IDS050PRK/CH-IU050RK' },
      { name: 'CH-IDS071PRK/CH-IU071RK' },
      { name: 'CH-IDH100PRK/CH-IU100RM' },
      { name: 'CH-IDH140PRK/CH-IU140RM' },
      { name: 'CH-IDH160PRK/CH-IU160RM' },
    ],
    downloads: kanalisiertePdf,
  },
  {
    id: 'CH-IDS071PRK | CH-IU071RK',
    categorie: 'kanalisierte klimaanlagen',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IDS071PRK/CH-IU071RK Kanalisierte indoor/outdoor',
    tags: 'Cooper&Hunter CH-IDS071PRK/CH-IU071RK Kanalisierte indoor/outdoor',
    image: kanalisierteImg1,
    gallery: [
      kanalisierteImg1,
      kanalisierteImg2,
      kanalisierteImg3,
      kanalisierteImg4,
      kanalisierteImg5,
      kanalisierteImg6,
      kanalisierteImg7,
      kanalisierteImg8,
      kanalisierteImg9,
      kanalisierteImg10,
    ],
    introduction:
      'Kanalklimageräte sind für den Einbau im Deckenraum konzipiert. Sie sind eine ideale Lösung für große Hotelräume, Restaurants oder Hörsäle. Der Vorteil dieses Systems ist die Möglichkeit der Luftverteilung durch Kanäle und die Gestaltung von Luftöffnungen an beliebiger Stelle in der Decke.',
    description: [
      '+8 Grad Funktion im Heizbetrieb',
      'Schaukelfunktion für Modelle 035-071',
      'DC-Inverter-Lüftermotoren',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      'Möglichkeit, den externen statischen Druck je nach Bedarf anzupassen',
      'Modelle 035 und 050 nur 20 cm hoch',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IDS035PRK/CH-IU035RK' },
      { name: 'CH-IDS050PRK/CH-IU050RK' },
      { name: 'CH-IDS071PRK/CH-IU071RK' },
      { name: 'CH-IDH100PRK/CH-IU100RM' },
      { name: 'CH-IDH140PRK/CH-IU140RM' },
      { name: 'CH-IDH160PRK/CH-IU160RM' },
    ],
    downloads: kanalisiertePdf,
  },
  {
    id: 'CH-IDH100PRK | CH-IU100RM',
    categorie: 'kanalisierte klimaanlagen',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IDH100PRK/CH-IU100RM Kanalisierte indoor/outdoor',
    tags: 'Cooper&Hunter CH-IDH100PRK/CH-IU100RM Kanalisierte indoor/outdoor',
    image: kanalisierteImg1,
    gallery: [
      kanalisierteImg1,
      kanalisierteImg2,
      kanalisierteImg3,
      kanalisierteImg4,
      kanalisierteImg5,
      kanalisierteImg6,
      kanalisierteImg7,
      kanalisierteImg8,
      kanalisierteImg9,
      kanalisierteImg10,
    ],
    introduction:
      'Kanalklimageräte sind für den Einbau im Deckenraum konzipiert. Sie sind eine ideale Lösung für große Hotelräume, Restaurants oder Hörsäle. Der Vorteil dieses Systems ist die Möglichkeit der Luftverteilung durch Kanäle und die Gestaltung von Luftöffnungen an beliebiger Stelle in der Decke.',
    description: [
      '+8 Grad Funktion im Heizbetrieb',
      'Schaukelfunktion für Modelle 035-071',
      'DC-Inverter-Lüftermotoren',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      'Möglichkeit, den externen statischen Druck je nach Bedarf anzupassen',
      'Modelle 035 und 050 nur 20 cm hoch',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IDS035PRK/CH-IU035RK' },
      { name: 'CH-IDS050PRK/CH-IU050RK' },
      { name: 'CH-IDS071PRK/CH-IU071RK' },
      { name: 'CH-IDH100PRK/CH-IU100RM' },
      { name: 'CH-IDH140PRK/CH-IU140RM' },
      { name: 'CH-IDH160PRK/CH-IU160RM' },
    ],
    downloads: kanalisiertePdf,
  },
  {
    id: 'CH-IDH140PRK | CH-IU140RM',
    categorie: 'kanalisierte klimaanlagen',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IDH140PRK/CH-IU140RM Kanalisierte indoor/outdoor',
    tags: 'Cooper&Hunter CH-IDH140PRK/CH-IU140RM Kanalisierte indoor/outdoor',
    image: kanalisierteImg1,
    gallery: [
      kanalisierteImg1,
      kanalisierteImg2,
      kanalisierteImg3,
      kanalisierteImg4,
      kanalisierteImg5,
      kanalisierteImg6,
      kanalisierteImg7,
      kanalisierteImg8,
      kanalisierteImg9,
      kanalisierteImg10,
    ],
    introduction:
      'Kanalklimageräte sind für den Einbau im Deckenraum konzipiert. Sie sind eine ideale Lösung für große Hotelräume, Restaurants oder Hörsäle. Der Vorteil dieses Systems ist die Möglichkeit der Luftverteilung durch Kanäle und die Gestaltung von Luftöffnungen an beliebiger Stelle in der Decke.',
    description: [
      '+8 Grad Funktion im Heizbetrieb',
      'Schaukelfunktion für Modelle 035-071',
      'DC-Inverter-Lüftermotoren',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      'Möglichkeit, den externen statischen Druck je nach Bedarf anzupassen',
      'Modelle 035 und 050 nur 20 cm hoch',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IDS035PRK/CH-IU035RK' },
      { name: 'CH-IDS050PRK/CH-IU050RK' },
      { name: 'CH-IDS071PRK/CH-IU071RK' },
      { name: 'CH-IDH100PRK/CH-IU100RM' },
      { name: 'CH-IDH140PRK/CH-IU140RM' },
      { name: 'CH-IDH160PRK/CH-IU160RM' },
    ],
    downloads: kanalisiertePdf,
  },
  {
    id: 'CH-IDH160PRK | CH-IU160RM',
    categorie: 'kanalisierte klimaanlagen',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IDH160PRK/CH-IU160RM Kanalisierte indoor/outdoor',
    tags: 'Cooper&Hunter CH-IDH160PRK/CH-IU160RM Kanalisierte indoor/outdoor',
    image: kanalisierteImg1,
    gallery: [
      kanalisierteImg1,
      kanalisierteImg2,
      kanalisierteImg3,
      kanalisierteImg4,
      kanalisierteImg5,
      kanalisierteImg6,
      kanalisierteImg7,
      kanalisierteImg8,
      kanalisierteImg9,
      kanalisierteImg10,
    ],
    introduction:
      'Kanalklimageräte sind für den Einbau im Deckenraum konzipiert. Sie sind eine ideale Lösung für große Hotelräume, Restaurants oder Hörsäle. Der Vorteil dieses Systems ist die Möglichkeit der Luftverteilung durch Kanäle und die Gestaltung von Luftöffnungen an beliebiger Stelle in der Decke.',
    description: [
      '+8 Grad Funktion im Heizbetrieb',
      'Schaukelfunktion für Modelle 035-071',
      'DC-Inverter-Lüftermotoren',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      'Möglichkeit, den externen statischen Druck je nach Bedarf anzupassen',
      'Modelle 035 und 050 nur 20 cm hoch',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IDS035PRK/CH-IU035RK' },
      { name: 'CH-IDS050PRK/CH-IU050RK' },
      { name: 'CH-IDS071PRK/CH-IU071RK' },
      { name: 'CH-IDH100PRK/CH-IU100RM' },
      { name: 'CH-IDH140PRK/CH-IU140RM' },
      { name: 'CH-IDH160PRK/CH-IU160RM' },
    ],
    downloads: kanalisiertePdf,
  },

  // KASETTEN
  {
    id: 'CH-IC035RK | CH-IU035RK',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC035RK/CH-IU035RK Kassetten indoor/outdoor',
    tags: 'Cooper&Hunter CH-IC035RK/CH-IU035RK Kassetten indoor/outdoor',
    image: kassettenImg1,
    gallery: [
      kassettenImg1,
      kassettenImg2,
      kassettenImg3,
      kassettenImg4,
      kassettenImg5,
      kassettenImg6,
      kassettenImg7,
      kassettenImg8,
    ],
    introduction:
      'Kassettengeräte passen sich perfekt dem Raum an, und die 4-Wege-Luftzufuhr sorgt für Komfort bei der Nutzung. Die kompakte Bauweise des Geräts ermöglicht eine einfache Installation auch auf engstem Raum unter Beibehaltung der Betriebsparameter.',
    description: [
      '360-Grad-Umfangspanel',
      'Lüftermotor und DC-Inverter-Wasserpumpe',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      '+8 Grad Funktion im Heizbetrieb',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
      'Kabelgebundener XK117-Controller - Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IC035RK/CH-IU035RK' },
      { name: 'CH-IC050RK/CH-IU050RK' },
      { name: 'CH-IC071RK/CH-IU071RK' },
      { name: 'CH-IC100RK/CH-IU100RM' },
      { name: 'CH-IC140RK/CH-IU140RM' },
      { name: 'CH-IC160RK/CH-IU160RM' },
    ],
    downloads: kassettenPdf,
  },
  {
    id: 'CH-IC050RK | CH-IU050RK',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC050RK/CH-IU050RK Kassetten indoor/outdoor',
    tags: 'Cooper&Hunter CH-IC050RK/CH-IU050RK Kassetten indoor/outdoor',
    image: kassettenImg1,
    gallery: [
      kassettenImg1,
      kassettenImg2,
      kassettenImg3,
      kassettenImg4,
      kassettenImg5,
      kassettenImg6,
      kassettenImg7,
      kassettenImg8,
    ],
    introduction:
      'Kassettengeräte passen sich perfekt dem Raum an, und die 4-Wege-Luftzufuhr sorgt für Komfort bei der Nutzung. Die kompakte Bauweise des Geräts ermöglicht eine einfache Installation auch auf engstem Raum unter Beibehaltung der Betriebsparameter.',
    description: [
      '360-Grad-Umfangspanel',
      'Lüftermotor und DC-Inverter-Wasserpumpe',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      '+8 Grad Funktion im Heizbetrieb',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
      'Kabelgebundener XK117-Controller - Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IC035RK/CH-IU035RK' },
      { name: 'CH-IC050RK/CH-IU050RK' },
      { name: 'CH-IC071RK/CH-IU071RK' },
      { name: 'CH-IC100RK/CH-IU100RM' },
      { name: 'CH-IC140RK/CH-IU140RM' },
      { name: 'CH-IC160RK/CH-IU160RM' },
    ],
    downloads: kassettenPdf,
  },
  {
    id: 'CH-IC071RK | CH-IU071RK',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC071RK/CH-IU071RK Kassetten indoor/outdoor',
    tags: 'Cooper&Hunter CH-IC071RK/CH-IU071RK Kassetten indoor/outdoor',
    image: kassettenImg1,
    gallery: [
      kassettenImg1,
      kassettenImg2,
      kassettenImg3,
      kassettenImg4,
      kassettenImg5,
      kassettenImg6,
      kassettenImg7,
      kassettenImg8,
    ],
    introduction:
      'Kassettengeräte passen sich perfekt dem Raum an, und die 4-Wege-Luftzufuhr sorgt für Komfort bei der Nutzung. Die kompakte Bauweise des Geräts ermöglicht eine einfache Installation auch auf engstem Raum unter Beibehaltung der Betriebsparameter.',
    description: [
      '360-Grad-Umfangspanel',
      'Lüftermotor und DC-Inverter-Wasserpumpe',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      '+8 Grad Funktion im Heizbetrieb',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
      'Kabelgebundener XK117-Controller - Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IC035RK/CH-IU035RK' },
      { name: 'CH-IC050RK/CH-IU050RK' },
      { name: 'CH-IC071RK/CH-IU071RK' },
      { name: 'CH-IC100RK/CH-IU100RM' },
      { name: 'CH-IC140RK/CH-IU140RM' },
      { name: 'CH-IC160RK/CH-IU160RM' },
    ],
    downloads: kassettenPdf,
  },
  {
    id: 'CH-IC100RK | CH-IU100RM',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC100RK/CH-IU100RM Kassetten indoor/outdoor',
    tags: 'Cooper&Hunter CH-IC100RK/CH-IU100RM Kassetten indoor/outdoor',
    image: kassettenImg1,
    gallery: [
      kassettenImg1,
      kassettenImg2,
      kassettenImg3,
      kassettenImg4,
      kassettenImg5,
      kassettenImg6,
      kassettenImg7,
      kassettenImg8,
    ],
    introduction:
      'Kassettengeräte passen sich perfekt dem Raum an, und die 4-Wege-Luftzufuhr sorgt für Komfort bei der Nutzung. Die kompakte Bauweise des Geräts ermöglicht eine einfache Installation auch auf engstem Raum unter Beibehaltung der Betriebsparameter.',
    description: [
      '360-Grad-Umfangspanel',
      'Lüftermotor und DC-Inverter-Wasserpumpe',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      '+8 Grad Funktion im Heizbetrieb',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
      'Kabelgebundener XK117-Controller - Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IC035RK/CH-IU035RK' },
      { name: 'CH-IC050RK/CH-IU050RK' },
      { name: 'CH-IC071RK/CH-IU071RK' },
      { name: 'CH-IC100RK/CH-IU100RM' },
      { name: 'CH-IC140RK/CH-IU140RM' },
      { name: 'CH-IC160RK/CH-IU160RM' },
    ],
    downloads: kassettenPdf,
  },
  {
    id: 'CH-IC140RK | CH-IU140RM',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC140RK/CH-IU140RM Kassetten indoor/outdoor',
    tags: 'Cooper&Hunter CH-IC140RK/CH-IU140RM Kassetten indoor/outdoor',
    image: kassettenImg1,
    gallery: [
      kassettenImg1,
      kassettenImg2,
      kassettenImg3,
      kassettenImg4,
      kassettenImg5,
      kassettenImg6,
      kassettenImg7,
      kassettenImg8,
    ],
    introduction:
      'Kassettengeräte passen sich perfekt dem Raum an, und die 4-Wege-Luftzufuhr sorgt für Komfort bei der Nutzung. Die kompakte Bauweise des Geräts ermöglicht eine einfache Installation auch auf engstem Raum unter Beibehaltung der Betriebsparameter.',
    description: [
      '360-Grad-Umfangspanel',
      'Lüftermotor und DC-Inverter-Wasserpumpe',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      '+8 Grad Funktion im Heizbetrieb',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
      'Kabelgebundener XK117-Controller - Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IC035RK/CH-IU035RK' },
      { name: 'CH-IC050RK/CH-IU050RK' },
      { name: 'CH-IC071RK/CH-IU071RK' },
      { name: 'CH-IC100RK/CH-IU100RM' },
      { name: 'CH-IC140RK/CH-IU140RM' },
      { name: 'CH-IC160RK/CH-IU160RM' },
    ],
    downloads: kassettenPdf,
  },
  {
    id: 'CH-IC160RK | CH-IU160RM',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC160RK/CH-IU160RM Kassetten indoor/outdoor',
    tags: 'Cooper&Hunter CH-IC160RK/CH-IU160RM Kassetten indoor/outdoor',
    image: kassettenImg1,
    gallery: [
      kassettenImg1,
      kassettenImg2,
      kassettenImg3,
      kassettenImg4,
      kassettenImg5,
      kassettenImg6,
      kassettenImg7,
      kassettenImg8,
    ],
    introduction:
      'Kassettengeräte passen sich perfekt dem Raum an, und die 4-Wege-Luftzufuhr sorgt für Komfort bei der Nutzung. Die kompakte Bauweise des Geräts ermöglicht eine einfache Installation auch auf engstem Raum unter Beibehaltung der Betriebsparameter.',
    description: [
      '360-Grad-Umfangspanel',
      'Lüftermotor und DC-Inverter-Wasserpumpe',
      'Eingebaute Laugenpumpe - Förderhöhe 1 m',
      '+8 Grad Funktion im Heizbetrieb',
      'Möglichkeit der Frischluftzufuhr',
      'WiFi-Option',
      'Kabelgebundener XK117-Controller - Option',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-IC035RK/CH-IU035RK' },
      { name: 'CH-IC050RK/CH-IU050RK' },
      { name: 'CH-IC071RK/CH-IU071RK' },
      { name: 'CH-IC100RK/CH-IU100RM' },
      { name: 'CH-IC140RK/CH-IU140RM' },
      { name: 'CH-IC160RK/CH-IU160RM' },
    ],
    downloads: kassettenPdf,
  },

  // WARMEPUMPEN

  {
    id: 'ECOPOWER R290 Serie',
    categorie: 'warmepumpen',
    subcategorie: 'Wärmepumpen',
    title: 'Cooper&Hunter ECOPOWER R290 CH-HP15UIMPZK',
    tags: 'Cooper&Hunter ECOPOWER R290, CH-HP15UIMPZK',
    image: ecoPowerImg1,
    gallery: [ecoPowerImg1, ecoPowerImg2],
    introduction:
      'Die neuen C&H ECOPOWER R290 Geräte sind die "Premium"-Versionen der EVIPOWER-Serie. Sie wurden unter Verwendung der neuesten Technologien erstellt, die in EVI-Wärmepumpen verwendet werden. Sie erfüllen strenge Anforderungen an Effizienz, Stabilität und Geräuschemission. Die ECOPOWER R290-Serie verwendet R290 Freon (GWP = 3) als Kältemittel, das 30 % effizienter ist als das zuvor verwendete Kältemittel und verfügt außerdem über EVI DC INVERTER-Kompressoren. Die Kombination von R290 mit EVI-Technologie ermöglicht es der Wärmepumpe, eine Austrittstemperatur von bis zu + 70 Grad zu erreichen.',
    description: [
      'R290 - als freundliches Kältemittel. GWP = 3',
      'SCOP (+35°C) = 5,15',
      'Sehr leiser Betrieb. Nur 44dB(A)',
      'Das Gehäuse besteht aus hochwertigem ABS und ist sehr korrosionsbeständig, was eine lange Lebensdauer gewährleistet',
      'Energieklasse A +++',
      'Austrittstemperatur bis +70°C',
      'Zum Einsatz kam ein patentierter Rohrbündelwärmetauscher, der das Gerät ab dem Moment des Stromausfalls 24 Stunden lang vor dem Einfrieren des Wassers schützt',
      'Möglichkeit zur Steuerung von bis zu 3 Heizkreisen mit Umwälzpumpen (erweiterbar auf 5)',
      'W-lan',
      'Möglichkeit des Kaskadenbetriebs bis zu 5 Stück als Standard',
    ],
    modellData: [{ name: 'Modell' }, { name: 'CH-HP15UIMPZK' }],
    downloads: ecopowerPdf,
  },

  // EVIPOWER SERIE

  {
    id: 'EVIPOWER Serie',
    categorie: 'warmepumpen',
    subcategorie: 'Wärmepumpen',
    title: 'Cooper&Hunter EVIPOWER WECHSELRICHTER Serie',
    tags: 'Cooper&Hunter EVIPOWER, CH-HP08UIMPRK, CH-HP12UIMPRM, CH-HP20UIMPRM',
    image: eviPowerImg5,
    gallery: [
      eviPowerImg1,
      eviPowerImg2,
      eviPowerImg3,
      eviPowerImg4,
      eviPowerImg5,
      eviPowerImg6,
      eviPowerImg7,
      eviPowerImg8,
      eviPowerImg9,
    ],
    introduction:
      'Die neuen C&H EVIPOWER INVERTER-Geräte bieten maximalen Wohnkomfort dank der neuesten EVI-Technologie, die den Betriebsbereich des Geräts erweitert und es der Wärmepumpe ermöglicht, bei einer Außentemperatur von -25 °C Ausgangswasser mit 50 °C zu produzieren ist außerdem leise und schont die Umwelt, da als Kältemittel umweltfreundliches R32-Gas verwendet wird. Sie sind im Leistungsbereich von 8 kW für 1-phasige Geräte und von 12 bis 20 kW für 3-phasige Geräte erhältlich.',
    description: [
      'Ruhige Arbeit',
      '5-Zoll-Farb-Touchscreen-Bedienfeld',
      'Konfigurierbar mit BMS (RS485) und Fernbedienung',
      'Patentierter Rohrbündelwärmetauscher',
      'Grundfos Umwälzpumpe',
      'Panasonic EVI-Inverter-Kompressor',
      'Schnellstartsystem bei niedrigen Temperaturen',
      'Kaskadenbetrieb möglich',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-HP08UIMPRK' },
      { name: 'CH-HP12UIMPRM' },
      { name: 'CH-HP20UIMPRM' },
    ],
    downloads: evipowerPdf,
  },

  {
    id: 'EVIPOWER PREMIUM Serie',
    categorie: 'warmepumpen',
    subcategorie: 'Wärmepumpen',
    title: 'Cooper&Hunter EVIPOWER PREMIUM WECHSELRICHTER',
    tags: 'Cooper&Hunter EVIPOWER, CH-HP08UIMPRK-P, CH-HP12UIMPRM-P, CH-HP23UIMPRM-P',
    image: eviPowerPremiumImg1,
    gallery: [eviPowerPremiumImg1, eviPowerPremiumImg2],
    introduction:
      'Die neuen C&H-Geräte des Typs EVIPOWER INVERTER PREMIUM bieten maximalen Komfort zu Hause dank der neuesten EVI-Technologie, die den Betriebsbereich des Geräts erweitert und es der Wärmepumpe ermöglicht, Ausgangswasser von 50 ° C bei einer Außentemperatur von -25 zu produzieren °C, außerdem sind sie sehr leise und schonen die Umwelt, weil sie das umweltfreundliche R32-Gas als Kältemittel verwenden. Sie sind im Leistungsbereich von 8 kW für 1-phasige Geräte und von 12,5 bis 23 kW für 3-phasige Geräte erhältlich.',
    description: [
      'Exklusives hochwertiges Gehäusedesign aus ABS-Kunststoff',
      'Sehr leiser Betrieb für 23kW - nur 46dB (A)',
      '5-Zoll-Farb-Touchscreen-Bedienfeld',
      'Konfigurierbar mit BMS (RS485) und Fernbedienung',
      'Patentierter Rohrbündelwärmetauscher',
      'Grundfos Umwälzpumpe',
      'Panasonic EVI-Inverter-Kompressor',
      'Schnellstartsystem bei niedrigen Temperaturen',
      'Kaskadenbetrieb möglich',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-HP08UIMPRK-P' },
      { name: 'CH-HP12UIMPRM-P' },
      { name: 'CH-HP23UIMPRM-P' },
    ],
    downloads: evipowerPremiumPdf,
  },

  {
    id: 'EVIPOWER ON-OFF',
    categorie: 'warmepumpen',
    subcategorie: 'Wärmepumpen',
    title: 'Cooper&Hunter EVIPOWER ON/OFF SERIE',
    tags: 'Cooper&Hunter EVIPOWER on/off, CH-HP16UMNM, CH-HP31UMNM, CH-HP42UMNM, CH-HP84UMNM',
    image: eviPowerOnOffImg1,
    gallery: [
      eviPowerOnOffImg1,
      eviPowerOnOffImg2,
      eviPowerOnOffImg3,
      eviPowerOnOffImg4,
      eviPowerOnOffImg5,
    ],
    introduction:
      'C&H EVIPOWER On/Off-Geräte bieten maximalen Komfort dank der neuesten EVI-Technologie, die den Betriebsbereich des Geräts erweitert und es der Wärmepumpe ermöglicht, ein Ausgangswasser von 50°C bei einer Außentemperatur von -25°C zu erzeugen. Sie sind im Leistungsbereich von 16 bis 86kW für 3-Phasen erhältlich.',
    description: [
      'Ruhige Arbeit',
      '5-Zoll-Farb-Touchscreen-Bedienfeld',
      'Konfigurierbar mit BMS (RS485) und Fernbedienung',
      'Patentierter Rohrbündelwärmetauscher',
      'Hohe COP',
      'EVI Scroll-Copeland-Kompressor',
      'Schnellstartsystem bei niedrigen Temperaturen',
      'Kaskadenbetrieb möglich',
    ],
    modellData: [
      { name: 'Modell' },
      { name: 'CH-HP16UMNM' },
      { name: 'CH-HP31UMNM' },
      { name: 'CH-HP42UMNM' },
      { name: 'CH-HP84UMNM' },
    ],
    downloads: evipowerOnOffPdf,
  },

  {
    id: 'Heating, cooling and HWS',
    categorie: 'warmepumpen',
    justPdf: true,
    subcategorie: 'Wärmepumpen',
    title: 'Cooper&Hunter - Wärmepumpen 2022 product line',
    tags: 'wärmepumpen, Evipower, Inverter, 2022, warmepumpen',
    image: basicMainImg,
    downloads: warmeBasicPDF,
  },

  // HEATPUMPS - POOL
  {
    id: 'Eco Serie',
    categorie: 'schwimmbad-pool-wasser-warmepumpen',
    subcategorie: 'Pool Wärmepumpen',
    title: 'Turbo Inverter Serie - Eco Serie',
    tags: 'Turbo Inverter Series - Eco Series schwimmbad pool wasser Titanium warmepumpen CH-HP010LERK CH-HP015LERK CH-HP020LERK CH-HP030LERK CH-HP035LERK CH-HP040LERK CH-HP045LERK',
    image: pool2,
    gallery: [pool2],
    description: [
      'Wärmetauscher aus Titan',
      'Ozonsicheres Kältemittel R32',
      'Intelligentes Bedienfeld',
      'Hohe Effizienz',
      'Betriebstemperaturbereich von -7°C to +43°C',
      'Verwendung: Schwimmbäder bis 78.m3',
    ],
    data: [
      { name: 'Model', value: 'Epfohlenes Poolvolumen' },
      { name: 'CH-HP010LERK', value: '17 m³' },
      { name: 'CH-HP015LERK', value: '25 m³' },
      { name: 'CH-HP020LERK', value: '35 m³' },
      { name: 'CH-HP030LERK', value: '45 m³' },
      { name: 'CH-HP035LERK', value: '58 m³' },
      { name: 'CH-HP040LERK', value: '65 m³' },
      { name: 'CH-HP045LERK', value: '78 m³' },
    ],
    downloads: poolPDF,
  },
  {
    id: 'Turbo Inverter Serie',
    categorie: 'schwimmbad-pool-wasser-warmepumpen',
    subcategorie: 'Pool Wärmepumpen',
    title: 'Cooper&Hunter - Turbo Inverter Serie',
    tags: 'Cooper&Hunter - Turbo Inverter Series schwimmbad pool wasser Titanium warmepumpen CH-HP050LTIRK CH-HP050LTIRM CH-HP060LTIRK CH-HP060LTIRM CH-HP070LTIRK CH-HP070LTIRM CH-HP080LTIRK HP080LTIRM',
    image: pool1,
    gallery: [pool1],
    description: [
      'Wärmetauscher aus Titan',
      'Ozonsicheres Kältemittel R32',
      '5 Zoll Touch-Bedienfeld',
      'Hohe Effizienz',
      'Wi-Fi-Fernsteuerung',
      'Vertikaler Luftauslass',
      'Wenig Lärm',
      'Intelligentes Abtausystem',
      'Hohe Genauigkeit der Temperaturhaltung',
      'Betriebstemperaturbereich von -15°C to +43°C',
      'Verwendung: Schwimmbäder bis 136 m3',
    ],
    data: [
      { name: 'Model', value: 'Epfohlenes Poolvolumen' },
      { name: 'CH-HP050LTIRK', value: '42-84 m³' },
      { name: 'CH-HP050LTIRM', value: '42-84 m³' },
      { name: 'CH-HP060LTIRK', value: '50-100 m³' },
      { name: 'CH-HP060LTIRM', value: '50-100 m³' },
      { name: 'CH-HP070LTIRK', value: '58-116 m³' },
      { name: 'CH-HP070LTIRM', value: '60-120 m³' },
      { name: 'CH-HP080LTIRK', value: '68-136 m³' },
      { name: 'HP080LTIRM', value: '68-136 m³' },
    ],
    downloads: poolPDF,
  },
  {
    id: 'Boost Inverter Serie',
    categorie: 'schwimmbad-pool-wasser-warmepumpen',
    subcategorie: 'Pool Wärmepumpen',
    title: 'Cooper&Hunter - Boost Inverter Serie ',
    tags: 'Cooper&Hunter - Boost Inverter Series schwimmbad pool wasser Titanium warmepumpen CH-HP050LBIRK CH-HP060LBIRK CH-HP075LBIRK CH-HP090LBIRK CH-HP120LBIRK CH-HP169LBIRK CH-HP095LBIRM CH-HP120LBIRM CH-HP169LBIRM',
    image: pool3,
    gallery: [pool3],
    description: [
      'Wärmetauscher aus Titan',
      'Ozonsicheres Kältemittel R32',
      '5 Zoll Touch-Bedienfeld',
      'Hohe Effizienz',
      'Wi-Fi-Fernsteuerung',
      'Hohe Genauigkeit der Temperaturhaltung',
      'Betriebstemperaturbereich von -15°C to +43°C',
      'Verwendung: Schwimmbäder bis to 169 m3',
    ],
    data: [
      { name: 'Model', value: 'Epfohlenes Poolvolumen' },
      { name: 'CH-HP050LBIRK', value: '25-50 m³' },
      { name: 'CH-HP060LBIRK', value: '30-60 m³' },
      { name: 'CH-HP075LBIRK', value: '40-75 m³' },
      { name: 'CH-HP095LBIRK', value: '50-95 m³' },
      { name: 'CH-HP120LBIRК', value: '65-120 m³' },
      { name: 'CH-HP169LBIRK', value: '90-169 m³' },
      { name: 'CH-HP095LBIRM', value: '50-95 m³' },
      { name: 'CH-HP120LBIRM', value: '65-120 m³' },
      { name: 'CH-HP169LBIRM', value: '90-169 m³' },
    ],
    downloads: poolPDF,
  },
]

export default products
