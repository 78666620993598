import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Modal from '../components/Modal'
import products from '../helper/List'
import { useParams, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// IMAGES
import pdfImage from '../assets/icons/pdf-icon.svg'
import download from '../assets/images/mountains-bg.png'

const Product = () => {
  const { t } = useTranslation()
  const [clickedImg, setClickedImg] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)

  const productID = useParams().product

  const elem = products.find((product) => {
    return product.id.includes(productID)
  })

  const handelRotationRight = () => {
    const totalLength = elem.gallery.length
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0)
      const newUrl = elem.gallery[0]
      setClickedImg(newUrl)
      return newUrl
    }
    const newIndex = currentIndex + 1
    const img = elem.gallery
    const newUrl = img.filter((item) => {
      return img.indexOf(item) === newIndex
    })
    const newItem = newUrl[0]
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  const handelRotationLeft = () => {
    const totalLength = elem.gallery.length
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1)
      const newUrl = elem.gallery[totalLength - 1]
      setClickedImg(newUrl)
      return newUrl
    }
    const newIndex = currentIndex - 1
    const img = elem.gallery
    const newUrl = img.filter((item) => {
      return img.indexOf(item) === newIndex
    })
    const newItem = newUrl[0]
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  function capitalize(word) {
    const loweredCase = word.toLowerCase()
    return word[0].toUpperCase() + loweredCase.slice(1)
  }

  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content={elem.title} />
        <meta property='og:title' content={elem.title} />
        <meta property='og:url' content={window.location.href} />
        <meta
          property='og:image'
          content={`https://cooperklima.at${elem.image}`}
        />

        <link rel='manifest' href='./manifest.json' />
        <title>Cooper&amp;Hunter | {capitalize(elem.id)}</title>
      </Helmet>
      <div className='content-wrapper'>
        <div className='product-container'>
          <div className='product-description'>
            <h1>{elem.title}</h1>
            <hr />
            {elem.introduction && (
              <span className='product-introduction'>{elem.introduction}</span>
            )}
            <ul>
              {elem.description.map((description, id) => {
                return <li key={id}>{description}</li>
              })}
            </ul>
          </div>
          <div className='product-gallery'>
            <div
              className='hover-eye'
              onClick={() => setClickedImg(elem.image)}>
              <svg xmlns='http://www.w3.org/2000/svg' height='48' width='48'>
                <path d='M24 31.5Q27.55 31.5 30.025 29.025Q32.5 26.55 32.5 23Q32.5 19.45 30.025 16.975Q27.55 14.5 24 14.5Q20.45 14.5 17.975 16.975Q15.5 19.45 15.5 23Q15.5 26.55 17.975 29.025Q20.45 31.5 24 31.5ZM24 28.6Q21.65 28.6 20.025 26.975Q18.4 25.35 18.4 23Q18.4 20.65 20.025 19.025Q21.65 17.4 24 17.4Q26.35 17.4 27.975 19.025Q29.6 20.65 29.6 23Q29.6 25.35 27.975 26.975Q26.35 28.6 24 28.6ZM24 38Q16.7 38 10.8 33.85Q4.9 29.7 2 23Q4.9 16.3 10.8 12.15Q16.7 8 24 8Q31.3 8 37.2 12.15Q43.1 16.3 46 23Q43.1 29.7 37.2 33.85Q31.3 38 24 38ZM24 23Q24 23 24 23Q24 23 24 23Q24 23 24 23Q24 23 24 23Q24 23 24 23Q24 23 24 23Q24 23 24 23Q24 23 24 23ZM24 35Q30.05 35 35.125 31.725Q40.2 28.45 42.85 23Q40.2 17.55 35.125 14.275Q30.05 11 24 11Q17.95 11 12.875 14.275Q7.8 17.55 5.1 23Q7.8 28.45 12.875 31.725Q17.95 35 24 35Z' />
              </svg>
            </div>
            <img
              src={elem.image}
              alt={elem.title}
              onClick={() => setClickedImg(elem.image)}
            />
            <div className='small-image'>
              {elem.gallery.map((item, i) => (
                <img
                  src={item}
                  alt={elem.title}
                  key={i}
                  onClick={() => setClickedImg(item)}
                />
              ))}
            </div>

            {clickedImg && (
              <Modal
                clickedImg={clickedImg}
                handelRotationRight={handelRotationRight}
                setClickedImg={setClickedImg}
                handelRotationLeft={handelRotationLeft}
              />
            )}
          </div>
        </div>
        <React.Fragment>
          {elem.cooling && elem.heating && (
            <div className='product-long-description'>
              <div className='product-attributes'>
                <div className='product-attributes-description'>
                  <p>Cooling </p>
                  <strong>{elem.cooling} kW</strong>
                </div>
                <div className='product-attributes-description'>
                  <p>Heating</p>
                  <strong>{elem.heating} kW</strong>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
        <a
          className='table-link'
          href={elem.downloads}
          target='_blank'
          rel='noreferrer'>
          <>
            {elem.data && (
              <div className='datasheet-wrapper'>
                <h2>Parameters:</h2>
                <hr />
                <table className='datasheet-container'>
                  {elem.data.map((parameters, i) => (
                    <thead key={i}>
                      <tr className='head'>
                        <th scope='col'>{parameters.name} </th>
                      </tr>
                      <tr>
                        <td>{parameters.value}</td>
                      </tr>
                    </thead>
                  ))}
                </table>
              </div>
            )}
          </>
          <>
            {elem.modellData && (
              <div className='datasheet-wrapper'>
                <table className='datasheet-container'>
                  {elem.modellData.map((parameters, i) => (
                    <thead className='datasheet-table' key={i}>
                      <tr className='head'>
                        <th scope='col'>{parameters.name} </th>
                      </tr>
                    </thead>
                  ))}
                </table>
              </div>
            )}
          </>
        </a>
      </div>
      <div className='btn-back'>
        <NavLink to={`/categories/${useParams().id}`}>
          <button className='basic-btn'>&#8592; {t('back')}</button>
        </NavLink>
      </div>
      <a
        className='download-bg'
        href={elem.downloads}
        target='_blank'
        rel='noreferrer'>
        <div className='download-container'>
          <h2>Download specification</h2>
          <img src={pdfImage} alt='download pdf' />
        </div>
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 150px;
  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }

  .content-wrapper {
    width: 1168px;
    max-width: 90%;
    padding-block: 5rem;
    margin: auto;

    @media (max-width: 1024px) {
      padding: 2rem 0;
    }
  }

  .small-image {
    display: flex;
    flex-direction: column;
  }

  .product-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 0.5rem;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  }

  hr {
    width: 12%;
    height: 3px;
    border: none;
    background: var(--cooper-red);
    margin-bottom: 2.5rem;
  }

  .product-description {
    width: 60%;
    @media screen and (max-width: 1024px) {
      order: 2;
      width: 100%;
    }

    h1 {
      font-size: 1.5rem;
    }

    ul {
      margin-left: 1rem;
      list-style-type: none;

      li:before {
        content: '• ';
        color: var(--secondary-bg);
        font-weight: bold;
        display: inline-block;
        font-size: 1.3rem;
        width: 1rem;
        margin-left: -1rem;
      }

      li {
        font-size: 1.125rem;
      }
    }
  }

  .product-gallery {
    position: relative;
    width: 46%;
    display: flex;
    height: fit-content;
    align-items: center;
    gap: 5px;

    :hover .hover-eye {
      opacity: 1;
    }

    .hover-eye {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: calc(50% - 75px);
      background-color: rgba(0, 84, 163, 0.65);
      border-radius: 50%;
      width: 80px;
      height: 80px;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      @media screen and (max-width: 1024px) {
        display: none;
      }

      svg {
        fill: #fffeff;
        align-items: center;
      }
    }

    .small-image {
      width: 100%;
      display: flex;
      justify-content: center;
      max-height: 450px;
      overflow: auto;
      gap: 5px;
    }

    .small-image::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .product-gallery > img {
    width: 450px;
    object-fit: cover;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    .small-image {
      width: 90%;
      display: flex;
      flex-direction: row;
      margin-bottom: 3rem;
      overflow-y: hidden;
      overflow-x: auto;
    }
  }

  .small-image img {
    width: 75px;

    :hover {
      cursor: pointer;
    }
  }

  .product-attributes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .product-introduction {
    display: block;
    width: 95%;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 2rem;
    background-color: #f2f2f2;
    padding: 0.5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .product-long-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      margin-top: 2rem;
    }

    div {
      display: flex;
      flex-direction: column;

      p {
        width: 100%;
        font-size: 1.125rem;
      }

      .product-attributes-description {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 1024px) {
          margin-top: 2rem;
        }
      }
    }

    .product-attributes-description:first-child p {
      width: 20%;
      background-color: #e5e5e5;
      padding: 1.5rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    .product-attributes-description:nth-child(2) p {
      width: 20%;
      background-color: #f2f2f2;
      padding: 1.5rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    strong {
      padding: 1.5rem;
      width: 40%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    p {
      width: 60%;
      font-size: 1.125rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    ul {
      background-color: gray;
      display: flex;
      flex-direction: row;

      list-style-type: none;
    }
  }

  .download-bg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 10rem;
    background: url(${download}) no-repeat center/cover;
    margin: 3rem 0 0;

    .download-container {
      width: 100%;
      padding: 0 20%;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 1024px) {
        padding: 0 20% 0 5%;
      }
    }

    h2 {
      color: white;
      margin-right: 5rem;
    }
  }

  .btn-back {
    display: block;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 2rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .basic-btn {
    display: inline-block;
    color: var(--title-text-color);
    background: transparent;
    text-transform: uppercase;
    padding: 0.5rem 4rem;
    border: 2px solid var(--title-text-color);
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    :hover {
      background-color: var(--secondary-bg);
      color: var(--primary-bg);
      border-color: var(--secondary-bg);
    }

    @media screen and (max-width: 1024px) {
      width: 90%;
    }
  }

  .datasheet-wrapper {
    display: flex;
    max-width: 100%;
    flex-direction: column;
  }

  .datasheet-container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }
  }

  .table-link {
    color: #000;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  thead,
  tbody {
    height: 100px;

    @media screen and (max-width: 1024px) {
      display: flex;
      width: 100%;
      flex-direction: column;
      height: auto;
    }
  }

  .head {
    background-color: #f2f2f2;
  }

  table tr {
    border: 1px solid #ddd;
    padding: 0.35em;
  }

  table td {
    font-weight: 300;
    font-size: 0.9rem;
  }

  table th,
  table td {
    padding: 0.625em;
    text-align: center;
    height: 80px;
  }

  table th {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
  }

  table th:nth-of-type(1) {
    color: #000;
  }

  @media screen and (max-width: 1024px) {
    .datasheet-table {
      width: 100%;
    }
  }
`

export default Product
