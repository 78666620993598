import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

// import klima's data
import products from '../helper/List'

const Categories = () => {
  const [pageTitle, setPageTitle] = useState('')
  const term = useParams().id
  const { t } = useTranslation()

  const foundList = products.filter((product) => {
    return product.categorie.includes(term)
  })

  useEffect(() => {
    switch (term) {
      case 'split':
        setPageTitle('Split Klimaanlagen')
        break
      case 'multi-systeme':
        setPageTitle('Multi Klimaanlagen')
        break
      case 'kassetten':
        setPageTitle('Kassetten-Split Klimaanlagen')
        break
      case 'kanalisierte':
        setPageTitle('Kanalisierte Klimaanlagen')
        break
      case 'warmepumpen':
        setPageTitle('Wärmepumpen Klimaanlagen')
        break
      case 'schwimmbad-pool-wasser-warmepumpen':
        setPageTitle('Wärmepumpen Für wasser erhitzen in schwimmbecken')
        break
      default:
        setPageTitle('Warmepumpen - Schwimmbad')
    }
  }, [term])

  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content={`Cooperklima - ${pageTitle}`} />
        <meta property='og:title' content={`Cooperklima - ${pageTitle}`} />
        <meta property='og:url' content={window.location.href} />
        <title>Cooper&amp;Hunter - {pageTitle}</title>
      </Helmet>
      <div className='categorie-title'>
        <div className='title-elements'>
          <h2>{pageTitle}</h2>
          <hr />
        </div>
      </div>
      <div className='content-wrapper'>
        <div className='products-container'>
          {foundList.map((product) => (
            <React.Fragment key={product.id}>
              {product.image.length > 0 && !product.justPdf ? (
                <div className='product-card'>
                  <NavLink to={`/categories/${term}/${product.id}`}>
                    <div className='product-card__image'>
                      <img
                        loading='lazy'
                        src={product.image}
                        alt='product_image'
                      />
                    </div>
                    <div className='product-card__footer'>
                      <p>{product.id.toUpperCase()}</p>
                      <>
                        {product.subcategorie && (
                          <p className='subcategorie'>{product.subcategorie}</p>
                        )}
                      </>
                    </div>
                  </NavLink>
                </div>
              ) : (
                <div className='product-card'>
                  <a
                    href={`${product.downloads}`}
                    target='_blank'
                    rel='noreferrer'>
                    <div className='product-card__image'>
                      <img
                        loading='lazy'
                        src={product.image}
                        alt='product_image'
                      />
                    </div>
                    <div className='product-card__footer'>
                      <p>{product.id}</p>
                      <>
                        {product.subcategorie && (
                          <p className='subcategorie'>{product.subcategorie}</p>
                        )}
                      </>
                    </div>
                  </a>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className='btn-back'>
          <NavLink to='/'>
            <button className='basic-btn'>&#8592; {t('back')}</button>
          </NavLink>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 150px;
  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }
  .categorie-title {
    width: 100vw;
    height: 15vh;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid #0052a5;
    @media screen and (max-width: 1024px) {
      padding-inline: 5%;
      text-align: center;
    }

    h2 {
      font-size: var(--title-font-size);
      text-transform: capitalize;

      @media screen and (max-width: 1024px) {
        font-size: calc(var(--title-font-size) - 0.5rem);
      }
    }

    hr {
      width: 66px;
      height: 3px;
      border: none;
      background: var(--cooper-red);
      text-align: left;

      @media screen and (max-width: 1024px) {
        text-align: center;
        margin: auto;
      }
    }
  }
  .content-wrapper {
    width: 1168px;
    max-width: 90%;
    padding-block: 5rem;
    margin: auto;

    @media (max-width: 1024px) {
      padding: 3rem 0;
    }
  }

  .products-container {
    display: flex;
    flex-wrap: wrap;
  }

  .product-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: calc(33% - 2rem);
    margin-inline: 1rem;
    margin-bottom: 5rem;
    min-height: fit-content;
    justify-content: space-between;
    border: 1px solid #b3b3b3;
    padding: 0.5rem;
    transition: all 0.3s ease-in-out;

    :hover {
      /* background-color: #0052a5; */
      box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.35);
    }

    /* :hover p {
      color: white;
    } */

    @media screen and (max-width: 1024px) {
      max-width: 100%;
      margin-inline: 0;
      margin-bottom: 3rem;
    }

    .product-card__image {
      width: 100%;
      height: 300px;
    }

    .product-card__image img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .product-card__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-block: 1.2rem;
      text-align: center;
    }

    .subcategorie {
      text-transform: capitalize;
      font-weight: 700;
    }
  }

  .btn-back {
    display: block;
    justify-content: center;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .basic-btn {
    display: inline-block;
    color: var(--title-text-color);
    background: transparent;
    text-transform: uppercase;
    padding: 0.5rem 4rem;
    border: 2px solid var(--title-text-color);
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    :hover {
      background-color: var(--secondary-bg);
      color: var(--primary-bg);
      border-color: var(--secondary-bg);
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
`

export default Categories
