import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useParams, NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  certContents,
  catalogContents,
  dataContents,
  manualContents,
} from '../helper/DocDownloads'

// images
import mountainImage from '../assets/images/mountains-download.png'
import manualImage from '../assets/images/download-manual.png'
import catImage from '../assets/images/download-manual.png'
import dataImage from '../assets/images/download-spec.png'
import certImage from '../assets/images/download-cert.png'

// icons
import certIcon from '../assets/icons/certifications-white.svg'
import manualIcon from '../assets/icons/user-manuals-white.svg'
import catalogIcon from '../assets/icons/catalogue-white.svg'
import dataIcon from '../assets/icons/specifications-white.svg'
import certIconBlue from '../assets/icons/certifications.svg'
import manualIconBlue from '../assets/icons/user-manuals.svg'
import catalogIconBlue from '../assets/icons/catalogue.svg'
import dataIconBlue from '../assets/icons/specifications.svg'

const SingleDownload = () => {
  const { t } = useTranslation()
  const { term } = useParams()
  let navigate = useNavigate()

  const title = () => {
    if (term === 'certifications') {
      return <h1>{t('download-cert')}</h1>
    } else if (term === 'catalog') {
      return <h1>{t('download-catalog')}</h1>
    } else if (term === 'datasheets') {
      return <h1>{t('download-data')}</h1>
    } else if (term === 'user-manuals') {
      return <h1>{t('download-manual')}</h1>
    } else {
      navigate('/404')
    }
  }

  const icon = () => {
    if (term === 'catalog') {
      return <img src={catalogIcon} alt='catalogues' />
    } else if (term === 'certifications') {
      return <img src={certIcon} alt='certifications' />
    } else if (term === 'user-manuals') {
      return <img src={manualIcon} alt='user manuals' />
    } else if (term === 'datasheets') {
      return <img src={dataIcon} alt='user manuals' />
    }
  }

  const textIcon = () => {
    if (term === 'catalog') {
      return catalogIconBlue
    } else if (term === 'certifications') {
      return certIconBlue
    } else if (term === 'user-manuals') {
      return manualIconBlue
    } else if (term === 'datasheets') {
      return dataIconBlue
    }
  }

  const image = () => {
    if (term === 'catalog') {
      return <img src={catImage} alt='catalogues' className='cert-img' />
    } else if (term === 'certifications') {
      return <img src={certImage} alt='certifications' className='cert-img' />
    } else if (term === 'user-manuals') {
      return <img src={manualImage} alt='user manuals' className='cert-img' />
    } else if (term === 'datasheets') {
      return <img src={dataImage} alt='datasheets' className='cert-img' />
    }
  }

  const contents = () => {
    if (term === 'catalog') {
      return catalogContents
    } else if (term === 'certifications') {
      return certContents
    } else if (term === 'user-manuals') {
      return manualContents
    } else if (term === 'datasheets') {
      return dataContents
    }
  }

  // const text = () => {
  //   if (term === 'catalog') {
  //     return (
  //       <div className="text-container-cert">
  //         <h2>{t('download-catalog-title')}</h2>
  //         <p>{t('download-catalog-text')}</p>
  //       </div>
  //     );
  //   } else if (term === 'certifications') {
  //     return (
  //       <div className="text-container-cert">
  //         <h2>{t('download-cert-title')}</h2>
  //         <p>{t('download-cert-text')}</p>
  //       </div>
  //     );
  //   } else if (term === 'user-manuals') {
  //     return (
  //       <div className="text-container-cert">
  //         <h2>{t('download-manual-title')}</h2>
  //         <p>{t('download-manual-text')}</p>
  //       </div>
  //     );
  //   } else if (term === 'datasheets') {
  //     return (
  //       <div className="text-container-cert">
  //         <h2>{t('download-data-title')}</h2>
  //         <p>{t('download-data-text')}</p>
  //       </div>
  //     );
  //   }
  // };

  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content={term} />
        <title>Cooperklima | {term}</title>
      </Helmet>
      <div className='hero-upper-container'>
        <div className='hero-container'>
          {title()}
          {icon()}
        </div>
      </div>
      <div className='main-container'>
        <div className='upper-container-cert'>
          <div className='image-container'>{image()}</div>
          {/* {text()} */}
        </div>
        <div className='row'>
          {contents().map((content, i) => (
            <div className='column' key={i}>
              <div className='downloadable-image-container'>
                <img src={textIcon()} alt='icon' />
              </div>
              <div className='downloadable-text-container'>
                <div>
                  <h2>Models:</h2>
                  <p>{content.title}</p>
                </div>

                <a
                  className='download-button'
                  href={content.url}
                  target='_blank'
                  rel='noreferrer'>
                  <p>Download</p>
                </a>
              </div>
            </div>
          ))}
          <hr />
          <NavLink className='download-button back' to='/downloads'>
            <p>&#8592; {t('back')}</p>
          </NavLink>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .hero-upper-container {
    background: url(${mountainImage});
    height: 12rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .hero-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1168px;
    max-width: 90%;

    h1 {
      color: var(--primary-bg);
    }
  }

  .main-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .upper-container {
    display: flex;
    flex-direction: row;
    width: 1168px;
    max-width: 90%;
    margin-top: -2rem;
    margin-bottom: 6rem;
    .image-container {
      max-width: 50%;
      min-width: 50%;
      overflow: visible;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .upper-container-cert {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1168px;
    max-width: 90%;
    padding-bottom: 2rem;

    .image-container {
      /*   max-width: 50%;
      min-width: 50%; */
      margin: auto;
      overflow: visible;
      order: 2;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }

  .upper-container-manuals {
    display: flex;
    flex-direction: row;
    width: 1168px;
    max-width: 90%;
    margin-top: -4rem;
    margin-bottom: 6rem;
    .image-container {
      max-width: 50%;
      min-width: 50%;
      overflow: visible;
    }
    img {
      object-fit: cover;
      width: 900px;
      transform: translateX(-30%);
      height: 100%;
    }
  }

  .text-container {
    background-color: #e5e5e5;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: 4rem;
    margin-top: 4rem;
    h2 {
      margin-bottom: 2rem;
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }
    p {
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
    }
  }

  .text-container-cert {
    background-color: #e5e5e5;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-right: 4rem;
    margin-top: 2rem;
    z-index: 1;
    h2 {
      margin-bottom: 2rem;
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }
    p {
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
    }
  }

  .text-container-manuals {
    background-color: #e5e5e5;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: 4rem;
    margin-top: 6rem;
    z-index: 1;
    h2 {
      margin-bottom: 2rem;
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }
    p {
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
    }
  }

  .secondary-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: column;
    width: 1168px;
    max-width: 90%;
    margin-bottom: 5rem;
    gap: 5rem;

    @media screen and (max-width: 1024px) {
      gap: 0;
    }
  }

  .column {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5rem;

    p {
      font-weight: 600;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 2rem;
    }
    .downloadable-image-container {
      width: 10%;

      @media screen and (max-width: 1024px) {
        width: 20%;
      }

      img {
        width: 50%;
        height: 50%;

        @media screen and (max-width: 1024px) {
          width: 100%;
          height: 100%;
        }
      }
    }
    .downloadable-text-container {
      width: 100%;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        font-size: 16px;
      }
    }
  }

  .download-button {
    border: 2px solid #000;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    width: 30%;
    p {
      text-transform: uppercase;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: var(--paragraph-font-size);
    }
  }

  .download-button.back {
    display: block;
    margin: auto;
    text-align: center;
  }

  .download-button:hover {
    background-color: var(--title-text-color);
    cursor: pointer;
    p {
      color: var(--primary-bg);
    }
  }

  @media screen and (max-width: 1024px) {
    .upper-container {
      flex-direction: column;
      margin-top: 0;
      .image-container {
        max-width: 100%;
        min-width: 100%;
      }
      .text-container {
        margin: 0;
      }
    }

    .upper-container-cert {
      flex-direction: column;
      .image-container {
        max-width: 100%;
        min-width: 100%;
        img {
          width: 100%;
        }
      }
      .text-container-cert {
        margin: 0;
        order: 2;
      }
    }

    .upper-container-manuals {
      flex-direction: column;
      .image-container {
        max-width: 100%;
        min-width: 100%;
        img {
          width: 100%;
          transform: none;
        }
      }
      .text-container-manuals {
        margin: 0;
      }
    }

    .row {
      flex-direction: column;
    }
    .column:not(:last-child) {
      margin-bottom: 5rem;
    }
    .column {
      width: 100%;
      .downloadable-text-container {
        padding-right: 0;
      }
    }

    .download-button {
      width: 100%;
    }

    .downloadable-image-container img {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
`

export default SingleDownload
