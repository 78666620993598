import React from 'react'
import { NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import { useTranslation } from 'react-i18next'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// featured slider images
import warmepumpe from '../assets/images/heat-pump-outdoor2.webp'
import arctic from '../assets/products/SPLIT/Arctic/arctic2.webp'
import veritas from '../assets/products/SPLIT/Veritas/veritas1.webp'

function FeaturedProducts() {
  const { t } = useTranslation()
  const isMobile = window.innerWidth < 1024
  return (
    <>
      <h3 className='categories-subtitle'>{t('featured-products')}</h3>
      <hr className='hr-2' />
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={120}
        slidesPerView={!isMobile ? 3 : 1}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        speed={1000}
        className='swiper-container'>
        <SwiperSlide className='top-slide'>
          <NavLink to='/categories/schwimmbad-pool-wasser-warmepumpen'>
            <img
              loading='lazy'
              src={warmepumpe}
              alt='cooper&#38;hunter klima'
            />
          </NavLink>
        </SwiperSlide>
        <SwiperSlide className='top-slide'>
          <NavLink to='/categories/split'>
            <img loading='lazy' src={arctic} alt='cooper&#38;hunter klima' />
          </NavLink>
        </SwiperSlide>
        <SwiperSlide className='top-slide'>
          <NavLink to='/categories/split'>
            <img loading='lazy' src={veritas} alt='cooper&#38;hunter klima' />
          </NavLink>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default FeaturedProducts
