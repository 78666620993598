import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

// images
import contactBg from '../assets/images/contact.webp'
import contactBgMobile from '../assets/images/contact-mobile.webp'

const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Cooperklima - Kontakt' />
        <title>Cooperklima | Kontakt</title>
      </Helmet>
      <div className='contact-hero'>
        <div className='content-wrapper inner'>
          <h2>{t('kapcsolat')}</h2>
          <h1>{t('carousel-h2')}</h1>
        </div>
      </div>
      <div className='content-wrapper'>
        <div className='content-container'>
          <div className='left'>
            <div className='content-title'>
              <h3>{t('contact-title')}</h3>
              <hr />
            </div>
            <div className='row-1'>
              <div className='icon-box'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='48'
                  width='48'
                  fill='#0054a3'>
                  <path d='M24 33.1Q29.2 28.9 31.85 25.025Q34.5 21.15 34.5 17.8Q34.5 14.85 33.425 12.8Q32.35 10.75 30.775 9.45Q29.2 8.15 27.35 7.575Q25.5 7 24 7Q22.5 7 20.65 7.575Q18.8 8.15 17.225 9.45Q15.65 10.75 14.575 12.8Q13.5 14.85 13.5 17.8Q13.5 21.15 16.15 25.025Q18.8 28.9 24 33.1ZM24 36.9Q17.15 31.75 13.825 27.05Q10.5 22.35 10.5 17.8Q10.5 14.4 11.725 11.825Q12.95 9.25 14.9 7.5Q16.85 5.75 19.25 4.875Q21.65 4 24 4Q26.35 4 28.75 4.875Q31.15 5.75 33.1 7.5Q35.05 9.25 36.275 11.825Q37.5 14.4 37.5 17.8Q37.5 22.35 34.175 27.05Q30.85 31.75 24 36.9ZM24 21Q25.45 21 26.475 19.975Q27.5 18.95 27.5 17.5Q27.5 16.05 26.475 15.025Q25.45 14 24 14Q22.55 14 21.525 15.025Q20.5 16.05 20.5 17.5Q20.5 18.95 21.525 19.975Q22.55 21 24 21ZM10.5 44V41H37.5V44ZM24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Q24 17.8 24 17.8Z' />
                </svg>

                <h4 className='icon-title'>{t('address')}</h4>
                <p>{t('address-data')}</p>
              </div>
              <div className='icon-box'>
                <a href='tel:+436642174381'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='48'
                    width='48'
                    fill='#0054a3'>
                    <path d='M24 42.25Q24.65 42.25 25.075 41.825Q25.5 41.4 25.5 40.75Q25.5 40.1 25.075 39.675Q24.65 39.25 24 39.25Q23.35 39.25 22.925 39.675Q22.5 40.1 22.5 40.75Q22.5 41.4 22.925 41.825Q23.35 42.25 24 42.25ZM13 35.5H35V9.5H13ZM13 46Q11.8 46 10.9 45.1Q10 44.2 10 43V5Q10 3.8 10.9 2.9Q11.8 2 13 2H35Q36.2 2 37.1 2.9Q38 3.8 38 5V43Q38 44.2 37.1 45.1Q36.2 46 35 46ZM13 6.5H35V5Q35 5 35 5Q35 5 35 5H13Q13 5 13 5Q13 5 13 5ZM13 5Q13 5 13 5Q13 5 13 5V6.5V5Q13 5 13 5Q13 5 13 5ZM13 38.5V43Q13 43 13 43Q13 43 13 43H35Q35 43 35 43Q35 43 35 43V38.5ZM13 43Q13 43 13 43Q13 43 13 43V38.5V43Q13 43 13 43Q13 43 13 43Z' />
                  </svg>
                  <h4 className='icon-title'>{t('phone')}</h4>
                  <p>{t('phone-data')}</p>
                </a>
              </div>
            </div>
            <div className='row-2'>
              {/* <div className='icon-box'>
                <img src={fax} alt='fax-icon' />
                <h4 className='icon-title'>{t('fax')}</h4>
                <p>{t('fax-data')}</p>
              </div> */}
              <div className='icon-box'>
                <a href='mailto:office@cooperklima.at'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='48'
                    width='48'
                    fill='#0054a3'>
                    <path d='M7 40Q5.8 40 4.9 39.1Q4 38.2 4 37V11Q4 9.8 4.9 8.9Q5.8 8 7 8H41Q42.2 8 43.1 8.9Q44 9.8 44 11V37Q44 38.2 43.1 39.1Q42.2 40 41 40ZM24 24.9 7 13.75V37Q7 37 7 37Q7 37 7 37H41Q41 37 41 37Q41 37 41 37V13.75ZM24 21.9 40.8 11H7.25ZM7 13.75V11V13.75V37Q7 37 7 37Q7 37 7 37Q7 37 7 37Q7 37 7 37Z' />
                  </svg>
                  <h4 className='icon-title'>{t('mail')}</h4>
                  <p>{t('mail-data')}</p>
                </a>
              </div>
            </div>
          </div>
          <div className='right-map'>
            <iframe
              width='100%'
              height='100%'
              frameBorder='0'
              scrolling='no'
              marginHeight='0'
              marginWidth='0'
              title='map'
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.3908878621537!2d16.195902915998634!3d48.141268879223766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da7a3a2adfe13%3A0xf512c916279f6232!2sStorage24%20XXL%20Garagen%20-%20Lager%20-%20Hallen!5e0!3m2!1shu!2shu!4v1651685048043!5m2!1shu!2shu'></iframe>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background-color: #fff;
  margin-top: 135px;
  width: 100vw;
  height: 100%;

  @media screen and (max-width: 1024px) {
    margin-top: 100px;
    background-color: #f2f2f2;
  }
  .contact-hero {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    height: 28rem;
    background: url(${contactBg}) no-repeat center/cover;

    @media screen and (max-width: 1024px) {
      max-height: 75vh;
      background: url(${contactBgMobile}) no-repeat center/cover;
    }
  }
  .content-wrapper {
    width: 1168px;
    height: 80vh;
    max-width: 90%;
    padding-block: 6rem;
    margin: auto;

    @media screen and (max-width: 1024px) {
      padding-top: 0;
      padding-bottom: 0;
      height: auto;
      max-width: 100%;
    }

    h1,
    h2 {
      font-size: var(--main-title-font-size);
      color: var(--primary-bg);
      text-transform: uppercase;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.5rem);
      }
    }

    .content-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      h3 {
        font-size: calc(var(--paragraph-font-size) + 0.8rem);
        color: var(--title-text-color);

        @media (max-width: 1024px) {
          font-size: calc(var(--title-font-size) - 0.5rem);
          font-weight: 500;
          text-align: center;
          text-align: left;
        }
      }

      hr {
        width: 12%;
        height: 3px;
        border: none;
        background: var(--cooper-red);

        @media screen and (max-width: 1024px) {
          /* margin: auto; */
        }
      }

      .right-map {
        width: 50%;
        height: 100%;

        @media screen and (max-width: 1024px) {
          width: 100%;
          height: 50vh;
          margin: 0;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 4rem 2rem;
        width: 50%;
        height: 100%;
        background-color: #f2f2f2;

        @media screen and (max-width: 1024px) {
          width: 90%;
          padding: 2rem 0;
          min-height: 50vh;
        }

        img {
          height: 45px;
        }

        p {
          font-size: 1rem;
        }

        .row-1,
        .row-2 {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: center;

          @media screen and (max-width: 1024px) {
            flex-direction: column;
          }
        }

        .icon-box {
          display: flex;
          width: 50%;
          text-align: center;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding: 1.5rem;
          min-height: 170px;

          .mail {
            height: 35px;
          }

          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }
      }
    }
  }

  .material-symbols-outlined {
    color: var(--secondary-bg);
    font-size: 3rem;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    @media (max-width: 1024px) {
      padding: 0;
      margin: 6rem auto;

      h1,
      h2 {
        text-align: center;
      }
    }
  }
`

export default ContactUs
