import { NavLink, Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import React, { useState, useContext, useEffect, useRef } from 'react'
import { GlobalContext } from '../helper/GlobalContext'
import { useTranslation } from 'react-i18next'
import Announcer from './Announcer'
import products from '../helper/List'

// iport images
import logo from '../assets/logos/logo.svg'
import phone from '../assets/icons/phone.svg'
import mail from '../assets/icons/mail.svg'
import pin from '../assets/icons/pin.svg'
// import products PDF's -> heat-pump, VRF
import vrfProduct from '../assets/downloads/products/CHV5_catalogue_ENG.pdf'

const Navbar = () => {
  const [clicked, setClicked] = useState(false)
  const { setNavClicked, setAnimationCanStart } = useContext(GlobalContext)
  const { t } = useTranslation()

  function navClickHandle() {
    setAnimationCanStart(false)
    setNavClicked(true)
  }

  const filterPosts = (posts, query) => {
    if (!query) {
      posts = []
      return posts
    }
    return posts.filter((post) => {
      const postName = post.tags.toLowerCase()
      return postName.includes(query)
    })
  }

  const { search } = window.location
  const query = new URLSearchParams(search).get('')
  const [searchQuery, setSearchQuery] = useState(query || '')
  const filteredPosts = filterPosts(products, searchQuery)
  let navigate = useNavigate()
  const onSubmit = (e) => {
    setClicked(false)
    e.preventDefault()
    let dropdownContent = document.getElementById('search-dropdown-content')
    dropdownContent.style.display = 'none'
    navigate(`/search/${searchQuery}`)
  }

  useEffect(() => {
    let dropdownContent = document.getElementById('search-dropdown-content')
    searchQuery === ''
      ? (dropdownContent.style.display = 'none')
      : (dropdownContent.style.display = 'block')
  }, [searchQuery])

  const reference = useRef()

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (reference.current && !reference.current.contains(e.target)) {
        let dropdownContent = document.getElementById('search-dropdown-content')
        dropdownContent.style.display = 'none'
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [])

  return (
    <Wrapper id='nav'>
      <div className='header-top-container'>
        <div className='header-top'>
          <ul className='header-top__left'>
            <li>
              <img src={pin} alt='map icon' />

              {t('address-data')}
            </li>

            <li>
              <a href={`mailto:${t('mail-data')}`}>
                <img src={mail} alt='mail icon' />
                {t('mail-data')}
              </a>
            </li>
            <li>
              <img src={phone} alt='phone icon' />
              {t('phone-data')}
            </li>
          </ul>
          <div className='header-top__right'>
            <Announcer message={`List has ${filteredPosts.length} posts`} />
            <div className='search-icon'>
              <img src={logo} alt='search' />
            </div>

            <div className='search-dropdown' ref={reference}>
              <form
                action='/'
                method='get'
                autoComplete='off'
                onSubmit={onSubmit}
                className='dropbtn'
                activeclassname='active'>
                <label htmlFor='header-search'>
                  <span className='visually-hidden'>Suche</span>
                </label>

                <input
                  value={searchQuery}
                  onInput={(e) => {
                    setSearchQuery(e.target.value)
                  }}
                  type='text'
                  id='header-search'
                  placeholder='Suche'
                  name='s'
                />
                <button type='submit' className='visually-hidden'>
                  Suche
                </button>
              </form>

              <div
                className='search-dropdown-content'
                id='search-dropdown-content'>
                {filteredPosts.slice(0, 5).map((post, index) => (
                  <div
                    className='result-container'
                    onClick={() => {
                      let dropdownContent = document.getElementById(
                        'search-dropdown-content'
                      )
                      dropdownContent.style.display = 'none'
                      navigate('/')
                    }}
                    key={index}>
                    <img src={post.image} alt='klimatech' />
                    {!post.justPdf ? (
                      <a
                        href={`categories/${post.categorie}/${post.id}`}>{`${post.title.substring(
                        0,
                        30
                      )}...`}</a>
                    ) : (
                      <a
                        href={post.downloads}
                        target='_blank'
                        rel='noreferrer'>{`${post.title.substring(
                        0,
                        30
                      )}...`}</a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='menu-wrapper-container'>
        <div className='desktop-menu-wrapper'>
          <NavLink to='/' aria-label='back to home btn'>
            <span onClick={navClickHandle}>
              <img src={logo} alt='ch logo' className='logo' />
            </span>
          </NavLink>
          <div className='desktop-menu'>
            <NavLink to='/' activeclassname='active'>
              <span onClick={navClickHandle}>{t('home')}</span>
            </NavLink>
            <div className='dropdown'>
              <span className='dropbtn' activeclassname='active'>
                {t('catalogue')}
              </span>
              <div className='dropdown-content'>
                <NavLink to='/categories/split'>
                  {t('mini-split-system')}
                </NavLink>
                <NavLink to='/categories/multi-systeme'>
                  {t('multi-systems')}
                </NavLink>
                <NavLink to='/categories/kassetten'>
                  {t('casette-split-conditioners')}
                </NavLink>
                <a href={vrfProduct} target='_blank' rel='noreferrer'>
                  {t('VRF')}
                </a>
                <NavLink to='/categories/kanalisierte'>
                  {t('heatchannel')}
                </NavLink>
                <NavLink to='/categories/warmepumpen'>
                  {t('heat-pumps')}
                </NavLink>
                <NavLink to='/categories/schwimmbad-pool-wasser-warmepumpen'>
                  {t('heat-pumps-water')}
                </NavLink>
              </div>
            </div>
            <NavLink to='/about' activeclassname='active'>
              <span onClick={navClickHandle}>{t('about')}</span>
            </NavLink>
            <NavLink to='/downloads' activeclassname='active'>
              <span onClick={navClickHandle}>{t('downloads')}</span>
            </NavLink>
            <NavLink to='/contactus' activeclassname='active'>
              <span onClick={navClickHandle}>{t('contact')}</span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className='mobile-wrapper'>
        <NavLink to='/'>
          <span onClick={() => setClicked(false)}>
            <img src={logo} alt='ch logo' className='logo' />
          </span>
        </NavLink>
        <div className='mobile-nav'>
          <div className='hamburger-ul' onClick={() => setClicked(!clicked)}>
            <div className={`hamburger-1 ${clicked ? 'close-1' : ''}`}></div>
            <div className={`hamburger-2 ${clicked ? 'close-2' : ''}`}></div>
            <div className={`hamburger-3 ${clicked ? 'close-3' : ''}`}></div>
          </div>
          <div className={`mobile-menu ${clicked ? 'active' : ''}`}>
            <NavLink
              to='/'
              activeclassname='active'
              onClick={() => setClicked(false)}>
              <span className='mobile-menuitem'>{t('home')}</span>
            </NavLink>
            <div className='dropdown'>
              <span
                className='dropbtn mobile-menuitem'
                activeclassname='active'>
                {t('catalogue')}
              </span>
              <div
                className='dropdown-content'
                onClick={() => setClicked(false)}>
                <NavLink to='/categories/split'>
                  {t('mini-split-system')}
                </NavLink>
                <NavLink to='/categories/multi-systeme'>
                  {t('multi-systems')}
                </NavLink>
                <NavLink to='/categories/kassetten'>
                  {t('casette-split-conditioners')}
                </NavLink>
                <a href={vrfProduct} target='_blank' rel='noreferrer'>
                  {t('VRF')}
                </a>
                <NavLink to='/categories/kanalisierte'>
                  {t('heatchannel')}
                </NavLink>
                <NavLink to='/categories/warmepumpen'>
                  {t('heat-pumps')}
                </NavLink>
                <Link to='/categories/schwimmbad-pool-wasser-warmepumpen'>
                  {t('heat-pumps-water')}
                </Link>
              </div>
            </div>
            <Link to='/about' onClick={() => setClicked(false)}>
              <span className='mobile-menuitem'>{t('about')}</span>
            </Link>
            <Link to='/downloads' onClick={() => setClicked(false)}>
              <span className='mobile-menuitem'>{t('downloads')}</span>
            </Link>
            <Link to='/contactus' onClick={() => setClicked(false)}>
              <span className='mobile-menuitem'>{t('contact')}</span>
            </Link>
            <div className='mobile-search-container'>
              <form
                action='/'
                method='get'
                autoComplete='off'
                onSubmit={onSubmit}
                className='dropbtn'
                activeclassname='active'>
                <label htmlFor='header-search-mobile'>
                  <span className='visually-hidden'>Suche</span>
                </label>

                <input
                  value={searchQuery}
                  onInput={(e) => {
                    setSearchQuery(e.target.value)
                  }}
                  type='text'
                  id='header-search-mobile'
                  placeholder='Suche'
                  name='s'
                />
                <button type='submit' className='visually-hidden'>
                  Suche
                </button>
              </form>
              <div className='search-icon'>
                <img src={logo} alt='search' />
              </div>
            </div>

            <hr />

            <div className='header-bottom'>
              <div className='header-bottom-link'>
                <img src={pin} alt='pin icon' />
                {t('address-data')}
              </div>

              <a href={`mailto:${t('mail-data')}`}>
                <div className='header-bottom-link'>
                  <img src={mail} alt='mail icon' />
                  {t('mail-data')}
                </div>
              </a>
              <div>
                <a href='tel:+43 664 2174381'>
                  <div className='header-bottom-link'>
                    <img src={phone} alt='phone icon' />
                    {t('phone-data')}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  width: 100%;
  background: #ffffff;
  .search-icon {
    height: 36px;
    width: 36px;
    z-index: 1;
    padding: 0;
    transform: translateX(36px);
  }

  .search-icon img {
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  .result-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .result-container img {
    height: 50px;
    width: 50px;
    padding-left: 2%;
    padding-right: 2%;
  }

  .search-dropdown {
    position: relative;
    display: flex;
    width: 200px;
    height: 38px;
  }

  .search-dropdown form,
  .search-dropdown input {
    width: 100%;
    height: 100%;
  }
  .search-dropdown input {
    text-indent: 36px;
  }
  #search-dropdown-content {
    display: none;
    position: absolute;
    top: 38px;
    left: 1px;
    background-color: var(--primary-bg);
    width: 198px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 5px 5px;
    z-index: 100;
    pointer-events: all;
    transition: all 0.5s ease-in-out;
  }

  .search-dropdown-content a {
    color: var(--title-text-color);
    padding: 12px 16px;
    text-decoration: none;
    background-color: var(--primary-bg);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.15s ease;
  }

  .search-dropdown-content .result-container:hover {
    text-shadow: 0 0 1px #000;
    cursor: pointer;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .mobile-search-container {
    display: flex;
  }

  .mobile-search-container .search-icon img {
    padding: 0;
    width: 35px;
  }

  .mobile-search-container .search-icon {
    transform: translateX(-40px);
  }
  .mobile-search-container input {
    height: 100%;
    width: 320px;
    text-indent: 5px;
  }

  .header-top-container {
    width: 100%;
    background: var(--secondary-bg);
    height: 60px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .header-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    inset: 0 0 0 0;
    width: 1168px;
    max-width: 90%;
    height: 60px;
    margin: 0 auto;
    background-color: var(--secondary-bg);
    font-size: 0.8rem;
    z-index: 1;

    .header-top__left,
    .header-top__right {
      display: flex;
      flex-direction: row;
    }

    .header-top__left {
      width: 80%;
      justify-content: flex-start;

      img {
        width: 45px;
      }
    }

    .header-top__right {
      width: 25%;
      justify-content: flex-end;
    }

    li {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      list-style: none;
      color: var(--primary-bg);
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--primary-bg);
      }
    }

    img {
      padding-right: 0.5rem;
    }

    span {
      font-weight: 700;
    }
  }

  .menu-wrapper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background: var(--primary-bg);
    height: 75px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .desktop-menu-wrapper {
    display: flex;
    width: 1168px;
    max-width: 90%;
    height: 75px;
    flex-direction: row;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    font-size: var(--paragraph-font-size);
    gap: 2rem;
    color: var(--text-color);
    background-color: var(--primary-bg);

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .desktop-menu-wrapper .logo {
    height: 46px;
    width: 120px;
  }

  .desktop-menu-wrapper > a > li {
    height: 46px;
  }

  .mobile-wrapper .logo {
    height: 46px;
    width: 120px;
  }

  .mobile-wrapper > a > li {
    height: 46px;
  }

  .desktop-menu {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    font-size: 1rem;

    .dropdown {
      position: relative;
      display: flex;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      top: 40px;
      left: -5px;
      background-color: var(--primary-bg);
      width: 220px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      /* border-radius: 0 0 5px 5px; */
      z-index: 100;
      transition: all 0.5s ease-in-out;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
    }

    .dropdown-content a {
      color: var(--title-text-color);
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* transition: all 0.25s ease; */
    }

    .dropdown-content a:hover {
      color: #ffffff;
      background-color: var(--secondary-bg);
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    span {
      cursor: pointer;
      text-decoration: none;
      list-style: none;
      position: relative;
      transition: all 0.3s ease;
      text-transform: uppercase;
      font-weight: 700;
      font-family: 'Poppins', 'Arial';
      font-size: 1rem;
      padding: 8px 10px;
      color: #000;

      :hover {
        color: var(--secondary-bg);
      }
    }

    .active {
      position: relative;
      border-bottom: 3px solid var(--secondary-bg);
    }

    a {
      display: flex;
      justify-content: center;
    }

    span:hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: var(--box-color);
      color: var(--secondary-bg);
    }
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: 1024px) {
    .mobile-wrapper {
      display: flex;
      width: 90%;
      height: 100px;
      align-items: center;
      justify-content: space-between;
      /* padding-inline: 10%; */
      background: var(--primary-bg);
      /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
      li {
        list-style: none;
      }
    }

    .header-top {
      display: none;
    }

    .hamburger-ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;
      width: 22px;
      height: 25px;
      cursor: pointer;
    }

    .hamburger-1,
    .hamburger-2,
    .hamburger-3 {
      width: 20px;
      height: 3px;
      background-color: var(--title-text-color);
      transition: all 0.3s ease-in-out;
    }

    .close-1 {
      transform: rotate(45deg) translateY(6px);
    }
    .close-3 {
      transform: rotate(-45deg) translateY(-6px);
    }
    .close-2 {
      display: none;
    }

    .mobile-menu {
      position: absolute;
      top: 100px;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: var(--secondary-bg);
      width: 100%;
      height: calc(100vh - 100px);
      align-items: left;
      justify-content: space-evenly;
      transform: translateX(100%);
      transition: all 0.3s ease-in-out;
      padding: 2rem 8%;
      list-style: none;
    }

    hr {
      width: 12%;
      height: 3px;
      border: none;
      background: var(--primary-bg);

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .header-bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 35%;
      background-color: var(--banner-color);

      .header-bottom-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        color: var(--primary-bg);
        margin-bottom: 1.5rem;

        img {
          width: 35px;
        }

        span {
          padding-right: 0.5rem;
        }
      }

      .header-bottom-link p {
        color: var(--primary-bg);
      }

      .nyitva {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      img {
        width: 25px;
        filter: brightness(0) invert(1);
        padding-right: 0.5rem;
      }
      span {
        font-weight: 700;
      }
    }

    .active {
      transform: translateX(0);
    }

    .mobile-menuitem {
      font-weight: 300;
      position: relative;
      width: fit-content;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      text-decoration: none;
      color: var(--primary-bg);
    }

    .mobile-menuitem:hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--box-color);
      color: var(--secondary-bg);
      cursor: pointer;
    }

    .dropdown {
      position: relative;
      display: flex;
    }

    .dropdown-content {
      display: none;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 48px;
      left: -8px;
      background-color: var(--primary-bg);
      width: 90%;
      transition: all 0.5s ease-in-out;
      border-radius: 10px;
      z-index: 10;
      border: none;
      box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.23);
    }

    .dropdown-content a {
      position: relative;
      margin-left: 18px;
      color: var(--title-text-color);
      text-decoration: none;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      transition: all 0.15s ease;
    }

    .dropdown-content a:before {
      position: absolute;
      content: '◉';
      left: -18px;
      top: 0;
      color: var(--secondary-bg);
      text-decoration: none;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      transition: all 0.15s ease;
    }

    .dropdown-content a:hover {
      font-weight: 700;
    }

    .dropdown:hover .dropdown-content {
      display: flex;
    }
  }

  /* .basic-btn {
    display: inline-block;
    color: var(--title-text-color);
    background: var(--primary-bg);
    text-transform: uppercase;
    padding: 0.5rem 4rem;
    border: 2px solid var(--primary-bg);
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    :hover {
      background-color: var(--secondary-bg);
      color: var(--primary-bg);
      border-color: var(--secondary-bg);
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  } */
`

export default Navbar
